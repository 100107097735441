.review {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px;

    &--rating {
        $self: &;
        display: flex;
        align-items: center;
        max-width: 185px;
        flex: 1;
        white-space: nowrap;

        &__right {
            justify-content: end;

            #{ $self }_star {
                background: gold;
            }
        }

        &_star {
            background: $white;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
            display: inline-block;
            height: 25px;
            width: 25px;
            flex: none;

            &.inactive {
                background: $black-trans;
            }
        }
    }

    &--emblem {
        background-image: url("/assets/img/review-small.svg");
        background-repeat: no-repeat;
        width: 60px;
        height: 60px;
        color: $blue-dark;
        padding: 18px;
        font-size: 12px;

        &_big {
            background-image: url("/assets/img/review-large.svg");
            width: 200px;
            height: 240px;
            text-align: center;
            color: $white;
            padding: 85px 25px;
            font-size: 18px;
        }
    }

    &--reviewer {
        flex: 1;
        font-weight: bold;
    }

    &--comment {
        flex: 1;
        flex-basis: 100%;
        border-bottom: 1px solid $grey-darker;
    }

    &--reply {
        padding: 20px;
        font-style: italic;
    }

    &__footer {
        display: inline-block;
        float: right;
        margin: 3px 10px;
        margin-top: -10px;
    }
}
