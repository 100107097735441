.breadcrumb {
    background: none;
    margin: -52px 0 13px;
    padding: 15px 20px 0;

    @include breakpoint(sm) {
        display: none;
    }

    &-list {
        padding: 0;
        margin: 0;
        background: none;
        font-size: 12px;
        overflow: hidden;

        a,
        span {
            color: $white-trans;
        }
    }

    &-item + .breadcrumb-item::before {
        color: $white-trans;
    }
}
