.main-navigation {
    text-align: left;

    ul {
        display: block;
        margin: 0;

        li {
            display: block;
            padding: 0;

            @include breakpoint(md) {
                margin: 0;
            }

            a {
                display: block;
                color: $black;
                font-size: 16px;
                line-height: 24px;
                padding: 6px 5px;

                &:hover,
                &:focus {
                    color: $customer-color;
                    text-decoration: none;
                }
            }

            > .toggle-subnav {
                display: none;
            }

            ul {
                display: none;
            }

            &.has-subnav {
                position: relative;

                &.on,
                &.active {
                    > .toggle-subnav {
                        @include transform(rotate(-180deg));
                    }
                }

                &.active.on {
                    > .toggle-subnav {
                        @include transform(rotate(0));
                    }
                }

                > .toggle-subnav {
                    @include transition(transform .3s);
                    position: absolute;
                    top: 11px;
                    right: 0;
                    display: block;
                    width: 12px;
                    height: 6px;
                    padding: 0;
                    background: url("/assets/img/header-icons.svg") no-repeat -2px -82px;
                    outline: 0;
                }
            }

            &.active {
                > a {
                    color: $customer-color;
                }

                > ul {
                    display: block;
                }

                > .main-navigation {
                    &__header-subnav {
                        @include media-breakpoint-down(lg) {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &__header,
    &__header-meta {
        display: inline-block;
        margin: 5px 0 0;
        order: 2;

        @include media-breakpoint-down(lg) {
            display: block;
            width: 100%;
            margin: 0;
            order: 4;
        }

        ul {
            font-size: 0;
            line-height: 0;

            @include media-breakpoint-down(lg) {
                display: block;
            }

            li {
                position: relative;
                display: block;

                a {
                    color: $black;
                    padding: 5px;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 600;

                    @include media-breakpoint-down(lg) {
                        padding: 10px 0;
                    }

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }

                ul {
                    display: block;

                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }

                &.has-subnav {
                    > .toggle-subnav {
                        @include media-breakpoint-down(lg) {
                            display: block;
                            top: 5px;
                            width: 35px;
                            height: 35px;
                        }
                    }
                }

                &.meta {
                    display: none;

                    @include media-breakpoint-down(lg) {
                        display: block;
                    }
                }
            }
        }

        > ul {
            @include media-breakpoint-down(lg) {
                margin: 0 0 20px;
            }

            > li {
                display: inline-block;
                margin: 0;
                padding: 0 10px 23px;

                @include media-breakpoint-down(lg) {
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                > a {
                    @include media-breakpoint-down(lg) {
                        border-bottom: 1px solid $white-grey;
                    }
                }

                &:last-of-type {
                    > a {
                        @include media-breakpoint-down(lg) {
                            border-bottom: 0;
                        }
                    }
                }

                &:hover,
                &:focus,
                &.active {
                    > a {
                        color: $customer-color;
                        text-decoration: none;
                    }
                }

                &.has-subnav {
                    position: static;

                    @include media-breakpoint-down(lg) {
                        position: relative;
                        padding: 0;
                    }

                    > a {
                        position: relative;

                        &::after {
                            position: absolute;
                            top: 52px;
                            left: 0;
                            display: none;
                            width: 100%;
                            height: 5px;
                            content: "";
                            background: $blue;

                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }

                        span {
                            position: relative;
                            padding-right: 24px;

                            @include media-breakpoint-down(lg) {
                                padding-right: 0;
                            }
                        }

                        // Nesting to deep :/
                        span::after {
                            position: absolute;
                            top: 7px;
                            right: 0;
                            display: block;
                            width: 16px;
                            height: 10px;
                            content: "";
                            background: url("/assets/img/header-icons.svg") no-repeat -12px -95px;

                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        // Nesting to deep :/
                        > a::after {
                            display: block;

                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }

                        // Nesting to deep :/
                        > a span::after {
                            background-position: -12px -255px;
                        }
                    }

                    &:hover,
                    &:focus {
                        > .main-navigation {
                            display: block;

                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }

                        ~ .overlay {
                            display: block;

                            @include media-breakpoint-down(lg) {
                                display: none;
                            }
                        }
                    }

                    &.active {
                        > .main-navigation {
                            @include media-breakpoint-down(lg) {
                                display: block;
                            }
                        }
                    }
                }

                &.overlay {
                    position: fixed;
                    top: 0;
                    right: 0;
                    display: none;
                    width: 100%;
                    height: 100%;
                    content: "";
                    padding: 0;
                    background: $black-trans;
                    z-index: -1;

                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }
        }
    }

    &__header-meta {
        @include media-breakpoint-down(lg) {
            display: none;
        }

        > ul {
            > li {
                > a {
                    color: $text-color;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }

    &__header-subnav {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        width: 100%;
        background: $white;
        border-top: 1px solid $white-grey;

        @include media-breakpoint-down(lg) {
            position: static;
            padding: 0 0 0 20px;
            border: 0;
        }

        ul {
            display: block;
            margin: 0;

            li {
                a {
                    font-weight: 400;
                }
            }
        }

        .wrapper {
            @include media-breakpoint-down(lg) {
                max-width: none;
            }

            > ul {
                @include clearfix;
                position: static;
                display: block;
                margin: 20px -15px;

                @include media-breakpoint-down(lg) {
                    margin: 0 -15px;
                }

                > li {
                    float: left;
                    width: 25%;
                    padding: 0 15px;
                    position: static;

                    @include media-breakpoint-down(lg) {
                        float: none;
                        clear: both;
                        width: auto;
                        padding: 0;
                        margin: 8px 0;
                    }
                }
            }
        }

        .product {
            a {
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            > a {
                position: relative;
                font-size: 26px;
                line-height: 40px;
                padding-left: 55px;

                &::before {
                    @include border-radius(50%);
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    display: block;
                    width: 40px;
                    height: 40px;
                    content: "";
                    background: url("/assets/img/package-icons.svg") no-repeat 0 0;
                }
            }

            &__yellow {
                a {
                    color: $yellow;

                    &:hover,
                    &:focus {
                        color: $yellow;
                    }
                }

                > a {
                    &::before {
                        background-position: 0 0;
                    }
                }

                &.active {
                    a {
                        color: $yellow;
                    }
                }
            }

            &__purple {
                a {
                    color: $purple;

                    &:hover,
                    &:focus {
                        color: $purple;
                    }
                }

                > a {
                    &::before {
                        background-position: 0 -40px;
                    }
                }

                &.active {
                    a {
                        color: $purple;
                    }
                }
            }

            &__blue {
                a {
                    color: $blue;

                    &:hover,
                    &:focus {
                        color: $blue;
                    }
                }

                > a {
                    &::before {
                        background-position: 0 -80px;
                    }
                }

                &.active {
                    a {
                        color: $blue;
                    }
                }
            }

            &__red {
                a {
                    color: $red;

                    &:hover,
                    &:focus {
                        color: $red;
                    }
                }

                > a {
                    &::before {
                        background-position: 0 -120px;
                    }
                }

                &.active {
                    a {
                        color: $red;
                    }
                }
            }
        }
    }

    &__side {
        margin: 0 0 30px;

        ul {
            li {
                border-bottom: 1px solid $grey;

                &:last-of-type {
                    border: 0;
                }

                &.active {
                    > a {
                        border-bottom: 1px solid $grey;
                    }

                    ul li a {
                        border-bottom: 0;
                    }
                }

                a {
                    @include breakpoint(md) {
                        font-size: 14px;
                    }
                }

                ul li a {
                    padding-left: 15px;
                }
            }
        }
    }

    &__footer {
        display: inline-block;
        margin: 4px 0 19px;

        @include breakpoint(sm) {
            display: block;
            text-align: center;
        }

        ul {
            li {
                display: inline-block;
                margin: 0 8px;

                a {
                    color: $white;
                    padding: 0;
                    font-size: 14px;

                    &:hover {
                        color: $white;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
