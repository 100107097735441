.slider {
    display: block;
    position: relative;
    margin: 0 0 40px;
    overflow: hidden;

    @include breakpoint(md) {
        margin: 0;
    }

    @include breakpoint(sm) {
        margin: 0;
    }

    &--item {
        position: relative;
        height: 680px;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: cover;

        @include breakpoint(md) {
            height: 720px;
        }

        @include breakpoint(sm) {
            height: auto;
        }

        + .slider {
            &--item {
                display: none;
            }
        }

        p {
            max-height: 112px;
            overflow: hidden;
        }
    }

    &--image {
        display: none;

        @include breakpoint(sm) {
            display: block;
        }
    }

    &--content {
        position: absolute;
        right: 0;
        width: 26%;
        height: 100%;
        padding: 50px 30px;
        text-align: center;

        @include breakpoint(sm) {
            position: relative;
            width: 100%;
            height: 300px;
            padding: 30px;
        }

        h2,
        p {
            color: $white;
            position: relative;
            margin: 0 0 40px;

            @include breakpoint(sm) {
                margin: 0 0 15px;
            }
        }

        &::after {
            position: absolute;
            left: 50%;
            bottom: 100px;
            display: block;
            width: 26px;
            height: 26px;
            margin: 0;
            content: "";
            background: url("/assets/img/btn-icons.svg") no-repeat -7px -527px;

            @include breakpoint(sm) {
                display: none;
            }
        }

        &::before {
            @include opacity(.9);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            z-index: 0;

            @include breakpoint(sm) {
                @include opacity(1);
            }
        }

        &__blue {
            &::before {
                background-color: $blue;
            }
        }

        &__red {
            &::before {
                background-color: $red;
            }
        }

        &__orange {
            &::before {
                background-color: $orange;
            }
        }

        &__green {
            &::before {
                background-color: $green;
            }
        }

        &__purple {
            &::before {
                background-color: $purple;
            }
        }

        &__dark-purple {
            &::before {
                background-color: $dark-purple;
            }
        }
    }

    .owl {
        &-item {
            .slider-item {
                display: block;
            }
        }

        &-dots,
        &-nav {
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 0 15px;

            @include breakpoint(xl) {
                max-width: 1170px;
            }

            @include breakpoint(lg) {
                max-width: 960px;
            }

            @include breakpoint(md) {
                max-width: 720px;
            }

            @include breakpoint(sm) {
                max-width: 540px;
            }
        }

        &-dots {
            top: auto;
            bottom: 270px;
            z-index: 1;
            padding-left: 7.8%;
            padding-right: 15%;

            @include breakpoint(articlesmall) {
                padding-right: 17%;
            }

            @include media-breakpoint-down(lg) {
                bottom: 290px;
                padding-right: 18%;
            }

            @include breakpoint(md) {
                bottom: 35px;
                padding-left: .8%;
            }

            @include breakpoint(sm) {
                display: none;
            }
        }

        &-nav {
            top: auto;
            bottom: 270px;
            z-index: 1;
            padding-left: 7.8%;
            padding-right: 15%;

            @include breakpoint(articlesmall) {
                padding-right: 23%;
            }

            @include media-breakpoint-down(lg) {
                bottom: 290px;
                padding-right: 23%;
            }

            @include breakpoint(md) {
                bottom: 35px;
                padding-left: .8%;
            }

            @include breakpoint(sm) {
                display: none;
            }
        }
    }

    &.owl {
        &-carousel {
            display: block;
            z-index: 0;
        }
    }
}

.owl {
    &-dots {
        position: absolute;
        left: 0;
        right: 0;
        font-size: 0;
        line-height: 0;
        text-align: center;
    }

    &-dot {
        @include border-radius(50%);
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        background-color: transparent;
        border: 2px solid $white;

        &.active,
        &:hover {
            background-color: $white;
        }
    }

    &-nav {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 0;
    }

    &-prev,
    &-next {
        display: block;
        width: 50px;
        height: 50px;
        margin: -25px 0 0;
        overflow: hidden;
        text-indent: -99999px;
        background: url("/assets/img/slider-icons.svg") no-repeat 0 0;
    }

    &-prev {
        float: left;
        background-position: 0 0;
    }

    &-next {
        float: right;
        background-position: 0 -50px;
    }
}
