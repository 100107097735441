// animations and keyframes

// #4 http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

// Animation
@mixin animation($str) {
    animation: #{$str};
}

// Transitions

// #5 http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
@mixin transition($args...) {
    transition: $args;
}

@include keyframes(bounce) {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}
