button {
    cursor: pointer;
    border: 0;
    outline: none;

    &:focus,
    &:active {
        outline: none;
    }
}

.btn {
    @include border-radius(25px);
    position: relative;
    display: inline-block;
    height: 46px;
    border: 0;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    background-color: $blue;
    text-align: center;
    padding: 11px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
        color: $white;
        text-decoration: none;
        background-color: $blue-hover;
    }

    &__small {
        height: 40px;
        line-height: 18px;
    }

    &__big,
    &--primary__big {
        @include border-radius(26px);
        height: 54px;
        padding: 14px 30px;
    }

    &__border,
    &--primary__border {
        color: $blue;
        padding-top: 9px;
        padding-bottom: 9px;
        background: transparent;
        border: 1px solid $blue;

        &:hover {
            color: $white;
            background-color: $blue;
        }
    }

    &--icon {
        padding-left: 60px;

        &::after {
            @include border-radius(50%);
            position: absolute;
            top: 8px;
            left: 8px;
            display: block;
            width: 30px;
            height: 30px;
            content: "";
            background: $white url("/assets/img/btn-icons.svg") no-repeat 0 0;
        }

        &__social-share {
            &::after {
                background-position: 0 -20px;
            }
        }

        &__messenger {
            &::after {
                background-size: 32px;
                background-position: -1px -321px;
            }
        }

        &__whatsapp {
            &::after {
                @include icon("whatsapp", $customer-color, 20px, 20px);
            }
        }

        &__chat {
            &::after {
                background-position: -4px -4px;
                background-size: 35px;
            }
        }

        &__mail {
            &::after {
                background-size: 32px;
                background-position: -1px -353px;
            }
        }
    }

    &--orange {
        color: $white;
        background: $orange;

        &:hover {
            color: $white;
            background-color: $orange-hover;
        }

        &__border {
            color: $orange;
            padding-top: 9px;
            padding-bottom: 9px;
            background: transparent;
            border: 1px solid $orange;

            &:hover {
                color: $white;
                background-color: $orange;
            }
        }
    }

    &--purple {
        color: $white;
        background: $purple;

        &:hover {
            color: $white;
            background-color: $purple-dark;
        }

        &__border {
            color: $purple;
            padding-top: 9px;
            padding-bottom: 9px;
            background: transparent;
            border: 1px solid $purple;

            &:hover {
                color: $white;
                background-color: $purple;
            }
        }
    }

    &--dark-purple {
        background: $dark-purple;

        &:hover,
        &:focus {
            background: $dark-purple-hover;
        }
    }

    &--white {
        color: $text-color;
        background-color: $white;

        &:hover {
            color: $text-color;
            background-color: $white;
        }

        &__blue {
            color: $blue;
        }

        &__red {
            color: $red;
        }

        &__orange {
            color: $orange;
        }

        &__green {
            color: $green;
        }
    }

    &--green {
        color: $white;
        background-color: $green;

        &:hover {
            background-color: $green-hover;
        }
    }

    &--grey {
        color: $white;
        background-color: $grey-extra-dark;

        &:hover {
            color: $white;
            background-color: $black;
        }
    }

    &--grey-light {
        color: $grey-extra-dark;
        background-color: $grey;

        &:hover {
            color: $grey-extra-dark;
            background-color: $grey-dark;
        }
    }

    &--red {
        color: $white;
        background-color: $red;

        &:hover {
            color: $white;
            background-color: $red-hover;
        }
    }

    &--close {
        background: transparent;
        color: $black;
        font-weight: 600;
        padding-right: 28px;
        cursor: pointer;

        &::before {
            position: absolute;
            top: 50%;
            right: 0;
            display: block;
            width: 18px;
            height: 18px;
            content: "";
            transform: translateY(-50%);
            background: url("/assets/img/btn-cart-icons.svg") no-repeat center -39px;
        }

        &:hover {
            background-color: transparent;
            color: $green;
        }
    }

    &--cart {
        position: relative;
        padding-left: 45px;
        padding-right: 45px;

        &.active {
            &::before,
            &::after {
                @include border-radius(50%);
                position: absolute;
                top: 7px;
                display: block;
                width: 32px;
                height: 32px;
                content: "";
                background: url("/assets/img/btn-cart-icons.svg") no-repeat -1px -1px;
                border: 2px solid $white;
            }

            &::before {
                left: 7px;
            }

            &::after {
                right: 7px;
            }

            &:hover {
                color: transparent;
                background-color: $grey;

                &::before,
                &::after {
                    background-position: -2px -34px;
                    border-color: $grey-extra-dark;
                }

                span {
                    &::after {
                        position: absolute;
                        top: 11px;
                        left: 45px;
                        right: 45px;
                        display: block;
                        color: $grey-extra-dark;
                        text-align: center;
                        content: attr(data-remove-title);
                    }
                }
            }
        }
    }
}
