.form-control {
    &.hasDatepicker {
        &[readonly] {
            color: $grey-extra-dark;
            background: $grey-light-3;
            border: 1px solid $grey-extra-dark-border;
        }
    }
}

.ui-datepicker {
    background: $white;
    border: 1px solid $grey-light-3;

    .ui-datepicker-header {
        border-bottom: 1px solid $grey-light-3;

        .ui-datepicker-prev,
        .ui-datepicker-next {
            @include border-radius(3px);
            width: 36px;
            height: 36px;
            text-align: center;
        }

        .ui-datepicker-prev {
            &::after {
                line-height: 36px;
                content: "<";
            }
        }

        .ui-datepicker-next {
            &::after {
                line-height: 36px;
                content: ">";
            }
        }

        .ui-datepicker-prev-hover,
        .ui-datepicker-next-hover {
            cursor: pointer;

            &::after {
                color: $white;
            }
        }
    }

    .ui-state {
        &-default {
            background: $grey-light;
            color: $text-color;
            text-align: center;
        }

        &-highlight {
            background: $grey-darker;
            color: $white;
        }

        &-disabled {
            opacity: .4;
        }

        &-hover,
        &-active {
            background: $blue;
            color: $white !important; // scss-lint:disable ImportantRule
        }
    }

    .ui-datepicker-title {
        .ui-datepicker-month,
        .ui-datepicker-year {
            font-size: 14px;
            margin: 0 3px;
            padding: 3px 10px;
            background: $white;
            border: 1px solid $grey-light-3;
            appearance: none;
            user-select: none;
        }
    }
}

.installation_datepicker {
    .ui-datepicker {
        &-inline {
            width: 100%;
        }
    }

    &.error {
        .ui-datepicker {
            border-color: $red;
        }
    }
}
