.side {
    &--block {
        margin: 0 -15px;
        word-break: break-word;

        @include media-breakpoint-down(md) {
            margin: 0;
        }

        h2 {
            font-size: 26px;
            line-height: 34px;
        }
    }
}
