.message-bar {
    &--block {
        background: $orange;
        color: $white;
        font-size: 14px;
        line-height: 22px;
        padding: 20px 20px 5px;
        text-align: center;
        z-index: 9999;

        @include media-breakpoint-down(md) {
            z-index: 0;
        }
    }

    &--content {
        position: relative;
        display: inline-block;
        padding: 0 80px;

        @include breakpoint(sm) {
            padding: 0 60px;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }

        a {
            color: $white;
            text-decoration: underline;
        }
    }
}

.alert {
    border: 1px solid $grey;
    padding: 30px 15px 15px;
    text-align: center;
    margin: 0 0 30px;
}

.network {
    $self: &;

    &--overview {
        border: 1px solid $white-grey;
        padding: 10px 15px;
        margin-bottom: 30px;

        #{ $self }--item {
            margin-bottom: 10px;

            &__errors {
                border-bottom: 1px solid $red;
            }

            &__activities {
                border-bottom: 1px solid $orange;
            }

            h2 {
                margin-bottom: 10px;
            }
        }
    }

    &--list {
        p:last-of-type {
            margin-bottom: 0;
        }

        &--title {
            font-size: 26px;
            font-weight: 300;
            line-height: 40px;
            margin-bottom: 0;
        }

        &--date {
            margin-top: -10px;
            display: block;
        }

        &--item {
            border-bottom: 1px solid $white-grey;
            margin-bottom: 10px;
            padding-bottom: 10px;

            &:last-child {
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &--status {
        float: right;

        @include media-breakpoint-down(lg) {
            float: left;
        }

        &:hover,
        &.active {
            .network {
                &--toggle {
                    color: $blue;

                    &::before {
                        top: 52px;
                        background: $blue;

                        @include media-breakpoint-down(lg) {
                            top: 32px;
                        }
                    }

                    &::after {
                        background-position: -12px -255px;
                    }
                }

                &--block {
                    display: block;

                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }

            + .network {
                &--overlay {
                    display: block;
                }
            }
        }

        &:hover {
            &:not(.active) {
                + .network {
                    &--overlay {
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }
            }
        }

        &.active {
            .network {
                &--block {
                    display: block;

                    @include media-breakpoint-down(lg) {
                        display: block;

                        &::after {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            display: block;
                            width: 40px;
                            height: 40px;
                            content: "";
                            cursor: pointer;
                            background: url("/assets/img/header-icons.svg") no-repeat 0 -360px;
                        }
                    }
                }
            }
        }
    }

    &--toggle {
        position: relative;
        display: block;
        color: $text-color;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        padding: 5px 39px 28px 15px;
        margin: 5px 0 0;
        background: transparent;

        @include media-breakpoint-down(lg) {
            padding: 5px 39px 16px 15px;
        }

        &::before {
            position: absolute;
            top: 38px;
            left: 10px;
            right: 10px;
            display: block;
            height: 5px;
            content: "";
            background: $green url("/assets/img/network-bg.png") no-repeat right 5px;

            @include media-breakpoint-down(lg) {
                top: 32px;
            }
        }

        &.network-type-1 {
            &::before {
                background-position: right 0;
            }
        }

        &.network-type-2 {
            &::before {
                background-position: right -5px;
            }
        }

        &.network-type-3 {
            &::before {
                background-position: right -10px;
            }
        }

        &::after {
            position: absolute;
            top: 12px;
            right: 15px;
            display: block;
            width: 16px;
            height: 10px;
            content: "";
            background: url("/assets/img/header-icons.svg") no-repeat -12px -95px;
        }
    }

    &--block {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: none;
        padding: 25px 0 10px;
        background: $white;
        border-top: 1px solid $white-grey;
        z-index: 9999;

        @include media-breakpoint-down(lg) {
            @include border-radius(5px);
            position: fixed;
            top: 20px;
            left: 20px;
            right: 20px;
            width: auto;
        }
    }

    &--item {
        text-align: left;

        h2 {
            position: relative;
            padding: 0 0 0 50px;
            font-size: 26px;
            line-height: 40px;

            &::after {
                @include border-radius(50%);
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 40px;
                height: 40px;
                margin: -20px 0 0;
                content: "";
                background: url("/assets/img/network-icons.svg") no-repeat 0 0;
            }

            a {
                text-decoration: none;
            }
        }

        .list {
            margin: 0;

            li {
                margin: 0 0 $default-margin;
            }
        }

        &__status {
            color: $green;

            a {
                color: $green;
                text-decoration: underline;
            }

            h2 {
                color: $green;

                &::after {
                    background-position: 0 0;
                    background-color: $green;
                }
            }
        }

        &__activities {
            color: $orange;

            .list li a {
                color: $orange;

                &:hover,
                &:focus {
                    color: $orange-hover;
                    text-decoration: none;
                }
            }

            a {
                color: $orange;
                text-decoration: underline;

                &:hover,
                &:active {
                    color: $orange-hover;
                    text-decoration: none;
                }
            }

            h2 {
                color: $orange;

                &::after {
                    background-position: 0 -40px;
                    background-color: $orange;
                }
            }
        }

        &__errors {
            color: $red;

            .list li a {
                color: $red;

                &:hover,
                &:focus {
                    color: $red-hover;
                    text-decoration: none;
                }
            }

            a {
                color: $red;
                text-decoration: underline;

                &:hover,
                &:active {
                    color: $red-hover;
                    text-decoration: none;
                }
            }

            h2 {
                color: $red;

                &::after {
                    background-position: 0 -80px;
                    background-color: $red;
                }
            }
        }
    }

    &--overlay {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
        content: "";
        background-color: $black-trans;
        z-index: -1;

        @include media-breakpoint-down(lg) {
            z-index: 1;
        }
    }
}

.color {
    &--block {
        color: $white;
        background-color: $blue;

        h1,
        h2 {
            color: $white;
        }

        &__blue {
            background-color: $blue;
        }

        &__blue-trans {
            background-color: $blue-trans;
        }

        &__blue-gradient {
            @include gradient-hr($blue-gradient-1, $blue-gradient-2);
        }

        &__blue,
        &__blue-trans,
        &__blue-gradient {
            .btn {
                &--white {
                    color: $blue;
                }
            }
        }

        &__red {
            background-color: $red;
        }

        &__red-trans {
            background-color: $red-trans;
        }

        &__red-gradient {
            @include gradient-hr($red-gradient-1, $red-gradient-2);
        }

        &__red,
        &__red-trans,
        &__red-gradient {
            .btn {
                &--white {
                    color: $red;
                }
            }
        }

        &__orange {
            background-color: $orange;
        }

        &__orange-trans {
            background-color: $orange-trans;
        }

        &__orange,
        &__orange-trans {
            .btn {
                &--white {
                    color: $orange;
                }
            }
        }

        &__green {
            background-color: $green;
        }

        &__green-trans {
            background-color: $green-trans;
        }

        &__green,
        &__green-trans {
            .btn {
                &--white {
                    color: $green;
                }
            }
        }

        &__purple {
            background-color: $purple;
        }

        &__purple-trans {
            background-color: $purple-trans;
        }

        &__purple,
        &__purple-trans,
        &__purple-gradient {
            .btn {
                &--white {
                    color: $purple;
                }
            }
        }
    }
}

.intro {
    &--block {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding: 40px 0;
        margin: 0 0 40px;
        align-items: center;
        color: $white;
        background: $blue;

        @include breakpoint(sm) {
            display: none;
        }

        &.has-image {
            min-height: 160px;

            @include breakpoint(sm) {
                min-height: 118px;
            }
        }

        &.has-title {
            @include breakpoint(sm) {
                display: block;
            }
        }

        .breadcrumb {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            z-index: 999;
            padding: 10px 20px 0;
        }

        h1,
        h2 {
            color: $white;
        }

        &__blue {
            background-color: $blue;

            .intro {
                &--image {
                    &::after {
                        @include gradient-hr($blue, transparent);
                    }
                }
            }
        }

        &__red {
            background-color: $red;

            .intro {
                &--image {
                    &::after {
                        @include gradient-hr($red, transparent);
                    }
                }
            }
        }

        &__green {
            background-color: $green;

            .intro {
                &--image {
                    &::after {
                        @include gradient-hr($green, transparent);
                    }
                }
            }
        }

        &__orange {
            background-color: $orange;

            .intro {
                &--image {
                    &::after {
                        @include gradient-hr($orange, transparent);
                    }
                }
            }
        }

        &__purple {
            background-color: $purple;

            .intro {
                &--image {
                    &::after {
                        @include gradient-hr($purple, transparent);
                    }
                }
            }
        }
    }

    &--content {
        flex: 1;
        overflow: hidden;
        padding: 0 5px;
        position: relative;

        @include breakpoint(md) {
            padding: 0;
        }

        @include breakpoint(sm) {
            padding: 0;
        }

        &__center {
            margin: 20px 0 5px;

            @include breakpoint(md) {
                margin: 0;
            }

            @include breakpoint(sm) {
                margin: 0;
            }
        }

        h1 {
            font-size: 36px;
            line-height: 42px;
            font-weight: 600;

            + h2 {
                margin-top: -15px;
            }

            + p {
                margin-top: -15px;
            }
        }

        p {
            margin-bottom: 0;
        }

        h2 {
            font-size: 16px;
            line-height: 24px;
        }

        &__contact {
            h1 {
                font-weight: 300;
                font-size: 44px;
                line-height: 54px;
            }

            a[href^="tel"] {
                display: block;
                font-weight: 600;
            }
        }
    }

    &--image {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;

        @include breakpoint(sm) {
            display: none;
        }

        &::after {
            @include gradient-hr($blue, transparent);
            position: absolute;
            display: block;
            top: 0;
            width: 25%;
            height: 100%;
            content: "";

            @include breakpoint(sm) {
                display: none;
            }
        }
    }
}

.side {
    &--block {
        h2 {
            a {
                color: $text-color;
            }
        }
    }
}

// scss-lint:disable ImportantRule
.article {
    &--block {
        position: relative;
        overflow: hidden;
        background: $white;

        &__left {
            .article {
                &--content {
                    padding-left: 165px;

                    @include breakpoint(articlesmall) {
                        padding-left: 0;
                    }

                    @include media-breakpoint-down(lg) {
                        padding-left: 0;
                    }
                }

                &--image {
                    text-align: right;
                    float: right;
                }
            }

            .d-xl-block {
                @include breakpoint(articlesmall) {
                    display: none !important;
                }
            }

            .d-md-block {
                @media (min-width: 768px) and (max-width: 885px) {
                    display: none !important;
                }
            }

            .col-md-8 {
                @media (min-width: 768px) and (max-width: 885px) {
                    max-width: 55%;
                }
            }

            .col-xl-9 {
                @include breakpoint(articlesmall) {
                    max-width: 50%;
                }
            }

            .wrapper.m-md-0 {
                float: right;

                @include media-breakpoint-down(sm) {
                    float: none;
                }
            }
        }

        &__right {
            .article {
                &--content {
                    padding-right: 165px;

                    @include media-breakpoint-down(lg) {
                        padding-right: 0;
                    }
                }
            }

            .d-xl-block {
                @include breakpoint(articlesmall) {
                    display: none !important;
                }
            }

            .d-md-block {
                @media (min-width: 768px) and (max-width: 885px) {
                    display: none !important;
                }
            }

            .col-md-8 {
                @media (min-width: 768px) and (max-width: 885px) {
                    max-width: 55%;
                }
            }

            .col-xl-9 {
                @include breakpoint(articlesmall) {
                    max-width: 66%;
                }
            }
        }

        &__blue {
            background: $blue;
        }

        &__red {
            background: $red;
        }

        &__orange {
            background: $orange;
        }

        &__green {
            background: $green;
        }

        &__purple {
            background: $purple;
        }

        &__contact {
            margin: -40px 0 40px;
            border-bottom: 1px solid $grey;
            max-height: 324px;

            @include media-breakpoint-down(lg) {
                max-height: unset;
            }

            .d-xl-block {
                @include breakpoint(articlesmall) {
                    display: block !important;
                }

                &.col-md-1 {
                    @include breakpoint(articlesmall) {
                        display: none !important;
                    }
                }
            }

            .col-lg-4.order-2 {
                @include media-breakpoint-only(lg) {
                    max-width: 53%;
                    flex: 0 0 60%;
                }
            }

            .article {
                &--content {
                    padding: 30px 0 15px;

                    h3 {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600;
                        margin: 0;
                    }
                }

                &--image {
                    background: transparent;
                }
            }
        }
    }

    &--content {
        height: 100%;
        padding: 50px 0 30px;

        @include breakpoint(md) {
            padding: 30px 0 15px;
        }

        @include breakpoint(sm) {
            padding: 30px 0 15px;
        }

        .cta {
            margin: 40px 0 $default-margin;

            @include breakpoint(sm) {
                margin: 0 0 $default-margin;
            }
        }
    }

    &--image {
        margin: 0 -15px;
        height: 100%;
        display: inline-block;
        background: $white-grey;

        &__blue {
            background: $blue-hover;
        }

        &__red {
            background: $red-hover;
        }

        &__orange {
            background: $orange-hover;
        }

        &__green {
            background: $green-hover;
        }

        &__purple {
            background: $purple-hover;
        }
    }
}

.highlights {
    &--block {
        position: relative;
        margin: 0 0 40px;
        overflow: hidden;

        @include breakpoint(md) {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
        }

        @include breakpoint(sm) {
            margin: 0;
        }
    }

    &--zipcode {
        @include border-radius(20px);
        position: absolute;
        top: 32%;
        left: 50%;
        display: inline-block;
        color: $white;
        padding: 15px 15px 0;
        transform: translate(-50%, -50%);
        background: $black-trans;
        z-index: 1;

        @include breakpoint(smalllaptop) {
            left: 37%;
            transform: translate(-37%, -50%);
        }

        @include media-breakpoint-down(lg) {
            max-width: 370px;
        }

        @include breakpoint(md) {
            left: 40%;
        }

        @include breakpoint(sm) {
            @include border-radius(0);
            position: relative;
            left: 0;
            display: block;
            padding: 15px;
            transform: none;
            background: $white-grey;
        }

        h2 {
            color: $white;
            font-size: 26px;
            line-height: 36px;
            font-weight: 600;
            text-align: center;
            margin: 0 0 10px;

            @include breakpoint(sm) {
                color: $black;
            }
        }

        .form {
            &-zipcode {
                .form {
                    &-control {
                        @include media-breakpoint-down(sm) {
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }

    &--columns {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;

        @include breakpoint(md) {
            position: static;
            flex: 1 1 auto;
            max-width: 100%;
            order: 3;
        }

        @include breakpoint(sm) {
            position: relative;
            padding: 15px 0;
            background: $white;
        }
    }
}

.highlight {
    &--block {
        height: 100%;
        padding: 15px 15px 0;
        background: $white;
        overflow: hidden;
        position: relative;

        @include breakpoint(sm) {
            margin: 0 -15px;
            padding: 0 20px;
        }

        &::after {
            content: "";
            position: absolute;
            top: 5px;
            right: 15px;
            width: 30px;
            height: 22px;
            padding: 0;
            background: url("/assets/img/header-icons.svg") no-repeat center -87px;
            outline: 0;
            display: none;
            transition: transform .3s;
            transform: rotate(0);

            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        &.active {
            &::after {
                transform: rotate(-180deg);
            }
        }

        h2 {
            position: relative;
            font-size: 26px;
            line-height: 34px;

            @include breakpoint(sm) {
                padding: 0 0 10px;
                margin: 0 0 10px;
                border-bottom: 1px solid $grey-light;
            }

            button {
                position: absolute;
                top: 0;
                display: none;
                width: 100%;
                height: 100%;
                background: transparent;
                outline: none;

                @include breakpoint(sm) {
                    display: block;
                }
            }
        }

        &__red {
            h2 {
                color: $red;
            }
        }

        &__green {
            h2 {
                color: $green;
            }
        }

        &__blue {
            h2 {
                color: $blue;
            }
        }

        &__dark-purple {
            h2 {
                color: $dark-purple;
            }
        }
    }

    &--content {
        display: block;
        overflow: hidden;
        max-height: 230px;

        @include breakpoint(sm) {
            @include transition(max-height 250ms ease-out);
            max-height: 0;
        }

        &.active {
            max-height: 230px;
        }

        .list {
            li {
                border-bottom: 1px solid $grey-light;

                &:last-of-type {
                    border-bottom: 0;
                }

                a,
                span {
                    display: block;
                    color: $text-color;
                    padding-top: 9px;
                    padding-bottom: 9px;

                    &.subline {
                        margin-top: -5px;
                        padding: 0;
                    }

                    strong {
                        display: block;
                    }
                }
            }
        }
    }
}

// scss-lint:ensable ImportantRule

.chat {
    &--block {
        position: relative;
        height: 100%;
        padding: 0 0 60px;

        @include breakpoint(sm) {
            padding: 0;
        }

        .device-contact-info {
            @include media-breakpoint-down(lg) {
                padding: 6px 0 0 80px;
            }

            @include media-breakpoint-down(sm) {
                padding: 0;
            }

            &.d-xl-block {
                @include breakpoint(articlesmall) {
                    display: none !important;
                }
            }

            &.d-md-block {
                @include media-breakpoint-only(md) {
                    display: block !important;
                }
            }
        }
    }

    &--content {
        position: relative;
        overflow: hidden;
        padding: 6px 0 0 80px;

        @include breakpoint(sm) {
            padding: 0;
        }

        h2 {
            color: $blue;
            font-size: 26px;
            line-height: 34px;
        }

        &::before {
            @include border-radius(50%);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 50px;
            height: 50px;
            content: "";
            background: $blue url("/assets/img/btn-icons.svg") no-repeat 0 -50px;
            background-size: 50px;

            @include breakpoint(sm) {
                display: none;
            }
        }
    }

    &--buttons {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 0;
        line-height: 0;
        margin: 0 -10px;

        @include media-breakpoint-down(md) {
            position: static;
        }

        li {
            display: inline-block;
            width: 50%;
            padding: 0 10px;
            margin: 0 0 15px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            .btn {
                display: block;
                text-align: left;
            }
        }

        &__small {
            li {
                width: 33.33%;
            }

            .btn {
                &--icon {
                    font-size: 14px;
                    padding-left: 45px;
                }
            }
        }
    }
}

.product {
    &--list {
        @include make-row;
    }

    &--item {
        @include make-col(4, 12);
        padding: 0 15px;

        @include breakpoint(sm) {
            @include make-col(12, 12);
        }
    }

    &--block {
        position: relative;
        border: 1px solid $grey;
        padding: 30px 50px 25px;
        margin: 0 0 30px;
        text-align: center;
        overflow: hidden;
        z-index: 1;

        @include breakpoint(sm) {
            padding: 30px 25px 25px;
        }

        h2 {
            font-size: 26px;
            line-height: 30px;

            strong {
                display: block;
                font-weight: 600;
            }
        }

        &.active {
            border-width: 2px;

            &::before {
                @include transform(rotate(25deg));
                position: absolute;
                top: 10px;
                right: -70px;
                display: block;
                width: 300px;
                height: 56px;
                content: "";
                z-index: -1;
            }

            &::after {
                @include transform(rotate(25deg));
                @include opacity(.3);
                position: absolute;
                top: 10px;
                right: -70px;
                display: block;
                width: 300px;
                height: 56px;
                content: "";
                background: $black;
                z-index: -1;
            }
        }

        &__orange {
            &.active {
                border-color: $orange;

                &::before {
                    background-color: $orange;
                }

                .btn {
                    color: $white;
                    background-color: $orange;

                    &:hover,
                    &:focus {
                        background-color: $orange-hover;
                    }
                }
            }

            .product {
                &--label {
                    background-color: $orange;
                }

                &--specifications {
                    li {
                        &::before {
                            background-image: url("/assets/img/package-icons-3in1.svg");
                        }

                        h6 {
                            color: $orange;
                        }
                    }
                }
            }
        }

        &__purple {
            &.active {
                border-color: $purple;

                &::before {
                    background-color: $purple;
                }

                .btn {
                    color: $white;
                    background-color: $purple;

                    &:hover,
                    &:focus {
                        background-color: $purple-dark;
                    }
                }
            }

            .product {
                &--label {
                    background-color: $purple;
                }

                &--specifications {
                    li {
                        &::before {
                            background-image: url("/assets/img/package-icons-2in1.svg");
                        }

                        h6 {
                            color: $purple;
                        }
                    }
                }
            }
        }

        &__blue {
            &.active {
                border-color: $blue;

                &::before {
                    background-color: $blue;
                }

                .btn {
                    color: $white;
                    background-color: $blue;

                    &:hover,
                    &:focus {
                        background-color: $blue-dark;
                    }
                }
            }

            .product {
                &--label {
                    background-color: $blue;
                }

                &--specifications {
                    li {
                        &::before {
                            background-image: url("/assets/img/package-icons-internet.svg");
                        }

                        h6 {
                            color: $blue;
                        }
                    }
                }
            }
        }

        &__red {
            &.active {
                border-color: $red;

                &::before {
                    background-color: $red;
                }

                .btn {
                    color: $white;
                    background-color: $red;

                    &:hover,
                    &:focus {
                        background-color: $red-dark;
                    }
                }
            }

            .product {
                &--label {
                    background-color: $red;
                }

                &--specifications {
                    li {
                        &::before {
                            background-image: url("/assets/img/package-icons-tv-radio.svg");
                        }

                        h6 {
                            color: $red;
                        }
                    }
                }
            }
        }
    }

    &--label {
        @include transform(rotate(-25deg));
        position: absolute;
        top: 10px;
        left: -70px;
        display: block;
        width: 300px;
        height: 56px;
        padding: 10px 30%;
        color: $white;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
    }

    &--image {
        margin: 0 0 $default-margin;
    }

    &--specifications {
        margin: 0 0 $default-margin;
        padding: 0 20px;

        @include breakpoint(sm) {
            padding: 0;
        }

        li {
            position: relative;
            padding: 0 50px;
            overflow: hidden;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 40px;
                height: 40px;
                content: "";
                background-repeat: no-repeat;
                background-size: 40px 120px;
            }

            &.tv {
                &::before {
                    background-position: 0 0;
                }
            }

            &.internet {
                &::before {
                    background-position: 0 -40px;
                }
            }

            &.phone {
                &::before {
                    background-position: 0 -80px;
                }
            }

            h6 {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                margin: 0;
            }

            strong {
                display: block;
            }
        }
    }

    &--price {
        strong {
            display: block;
            font-size: 26px;
            line-height: 34px;
            font-weight: 600;
            margin: 0 0 15px;
        }
    }
}

.product-specifications {
    &--container {
        overflow: hidden;
        margin: 0 0 $default-margin;

        h3 {
            font-size: 36px;
            line-height: 44px;
            font-weight: 300;

            @include breakpoint(sm) {
                font-size: 26px;
                line-height: 34px;
            }
        }
    }

    &--list {
        max-width: 500px;
        margin: 0 0 $default-margin;

        @include breakpoint(md) {
            max-width: none;
        }

        @include breakpoint(sm) {
            max-width: none;
        }

        li {
            @include clearfix;
            margin: 0;
            border-bottom: 1px solid $grey;

            &:last-of-type {
                border-bottom: 0;
            }

            .label,
            .value {
                display: block;
                float: left;
                padding: 4px 0;

                @include breakpoint(sm) {
                    float: none;
                    clear: both;
                    width: auto;
                }
            }

            .label {
                width: 70%;

                @include breakpoint(sm) {
                    width: 100%;
                }
            }

            .value {
                width: 30%;

                @include breakpoint(sm) {
                    width: 100%;
                }
            }
        }
    }
}

.product-up-sell {
    &--container {
        overflow: hidden;
        margin: 0 0 $default-margin;

        h3 {
            font-size: 36px;
            line-height: 44px;
            font-weight: 600;

            @include breakpoint(sm) {
                font-size: 26px;
                line-height: 34px;
            }
        }
    }

    &--list {
        padding: 15px 8px 0;
        margin: 0 0 30px;
        border-top: 1px solid $grey;
        border-bottom: 1px solid $grey;
    }

    &--item {
        margin: 0 0 15px;
        border-bottom: 1px solid $grey;

        &:last-child {
            margin: 0;
            border-bottom: 0;
        }
    }

    &--block {
        @include make-row;
        margin: 0 -8px;

        &__orange {
            .product-up-sell {
                &--label {
                    background-color: $orange;
                }
            }
        }

        &__purple {
            .product-up-sell {
                &--label {
                    background-color: $purple;
                }
            }
        }

        &__blue {
            .product-up-sell {
                &--label {
                    background-color: $blue;
                }
            }
        }

        &__red {
            .product-up-sell {
                &--label {
                    background-color: $red;
                }
            }
        }
    }

    &--label {
        @include transform(rotate(-20deg));
        position: absolute;
        top: 24%;
        left: -18%;
        display: block;
        width: 140%;
        color: $white;
        font-size: 13px;
        line-height: 20px;
        font-weight: 600;
        padding: 6px 0;
        text-align: center;
    }

    &--image,
    &--title,
    &--price,
    &--action {
        padding: 0 8px;
    }

    &--image {
        @include make-col(2, 12);
        position: relative;
        overflow: hidden;

        @include breakpoint(sm) {
            @include make-col(12, 12);
        }

        img {
            margin: 0 0 $default-margin;
        }
    }

    &--title {
        @include make-col(5, 12);

        @include breakpoint(sm) {
            @include make-col(12, 12);
        }

        h2 {
            font-size: 26px;
            line-height: 34px;
            margin: 0;
        }

        span {
            display: block;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }
    }

    &--price {
        @include make-col(2, 12);
        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
        text-align: right;

        @include breakpoint(sm) {
            @include make-col(12, 12);
            text-align: left;
        }

        span {
            display: block;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }
    }

    &--action {
        @include make-col(3, 12);

        @include breakpoint(sm) {
            @include make-col(12, 12);
        }

        .btn {
            display: block;
            width: 100%;
        }
    }
}

.product-summary {
    &--block {
        border-bottom: 1px solid $grey;
        margin: 0 0 30px;

        h2 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
        }
    }

    &--content {
        border: 1px solid $grey;
        border-bottom: 0;
        padding: 15px 25px;

        &__orange {
            h2 {
                color: $orange;
            }

            .btn {
                color: $white;
                background-color: $orange;

                &:hover,
                &:focus {
                    background-color: $orange-hover;
                }
            }
        }

        &__purple {
            h2 {
                color: $purple;
            }

            .btn {
                color: $white;
                background-color: $purple;

                &:hover,
                &:focus {
                    background-color: $purple-dark;
                }
            }
        }

        &__blue {
            h2 {
                color: $blue;
            }

            .btn {
                color: $white;
                background-color: $blue;

                &:hover,
                &:focus {
                    background-color: $blue-dark;
                }
            }
        }

        &__red {
            h2 {
                color: $red;
            }

            .btn {
                color: $white;
                background-color: $red;

                &:hover,
                &:focus {
                    background-color: $red-dark;
                }
            }
        }

        &__secondary {
            background-color: $grey-light;

            h2 {
                color: $grey-extra-dark;
            }
        }
    }

    &--price {
        margin: 0 0 $default-margin;
        font-size: 42px;
        line-height: 50px;

        span {
            display: block;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &--text {
        margin-bottom: 20px;
        text-align: center;

        &--wrapper {
            text-align: left;
        }
    }

    &--contact {
        margin: 0 0 5px;

        li {
            margin: 0 0 10px;

            a {
                position: relative;
                display: block;
                color: $grey-extra-dark;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                text-decoration: none;
                padding: 8px 0 8px 50px;

                &::before {
                    @include border-radius(50%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 40px;
                    height: 40px;
                    content: "";
                    background: $grey-extra-dark url("/assets/img/btn-icons.svg") no-repeat 0 0;
                }

                &:hover,
                &:focus {
                    color: $black;

                    &::before {
                        background-color: $black;
                    }
                }
            }

            &.chat {
                a {
                    &::before {
                        background-position: 0 -40px;
                    }
                }
            }

            &.messenger {
                a {
                    &::before {
                        background-position: 0 -80px;
                    }
                }
            }

            &.mail {
                a {
                    &::before {
                        background-position: 0 -120px;
                    }
                }
            }

            &.phone {
                a {
                    &::before {
                        background-position: 0 -160px;
                    }
                }
            }

            &.call {
                a {
                    &::before {
                        background-position: 0 -480px;
                    }
                }
            }
        }

        &__purple {
            li a {
                &::before {
                    background-color: $purple;
                }

                &:hover,
                &:focus {
                    color: $purple-dark;

                    &::before {
                        background-color: $purple-dark;
                    }
                }
            }
        }
    }
}

.contact-person {
    &--block {
        margin: 0 0 $default-margin;
    }

    &--content {
        padding: 25px 0 10px;

        @include breakpoint(md) {
            padding: 0;
        }

        @include breakpoint(sm) {
            padding: 0;
        }
    }

    &--image {
        @include border-radius(50%);
        max-width: 170px;
        margin: 0 0 15px;
        overflow: hidden;

        @include breakpoint(md) {
            max-width: none;
        }

        @include breakpoint(sm) {
            max-width: none;
        }
    }

    &--types {
        font-size: 0;
        line-height: 0;
        margin: 0 0 $default-margin;

        li {
            display: inline-block;
            margin: 0 15px $default-margin 0;

            @include breakpoint(sm) {
                display: block;
                margin: 0 0 $default-margin;
            }

            a {
                position: relative;
                display: block;
                color: $customer-color;
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                text-decoration: none;
                padding: 8px 0 8px 50px;

                &::before {
                    @include border-radius(50%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 40px;
                    height: 40px;
                    content: "";
                    background: $blue url("/assets/img/btn-icons.svg") no-repeat 0 0;
                }

                &:hover,
                &:focus {
                    color: $blue-dark;

                    &::before {
                        background-color: $blue-dark;
                    }
                }
            }

            &.chat {
                a {
                    &::before {
                        background-position: 0 -40px;
                    }
                }
            }

            &.messenger {
                a {
                    &::before {
                        background-position: 0 -80px;
                    }
                }
            }

            &.mail {
                a {
                    &::before {
                        background-position: 0 -120px;
                    }
                }
            }

            &.phone {
                a {
                    &::before {
                        background-position: 0 -160px;
                    }
                }
            }

            &.call {
                a {
                    &::before {
                        background-position: 0 -480px;
                    }
                }
            }
        }
    }
}

.zipcode-check {
    &--block {
        border: 1px solid $grey;
        padding: 30px 15px 15px;
        margin: 0 0 30px;

        h2 {
            font-size: 26px;
            line-height: 34px;
        }

        &__orange {
            h2 {
                color: $orange;
            }

            .btn {
                &__border,
                &--primary__border {
                    color: $orange;
                    border-color: $orange;

                    &:hover,
                    &:focus {
                        color: $white;
                        background-color: $orange;
                    }
                }
            }
        }

        &__purple {
            h2 {
                color: $purple;
            }

            .btn {
                &__border,
                &--primary__border {
                    color: $purple;
                    border-color: $purple;

                    &:hover,
                    &:focus {
                        color: $white;
                        background-color: $purple;
                    }
                }
            }
        }

        &__blue {
            h2 {
                color: $blue;
            }

            .btn {
                &__border,
                &--primary__border {
                    color: $blue;
                    border-color: $blue;

                    &:hover,
                    &:focus {
                        color: $white;
                        background-color: $blue;
                    }
                }
            }
        }

        &__red {
            h2 {
                color: $red;
            }

            .btn {
                &__border,
                &--primary__border {
                    color: $red;
                    border-color: $red;

                    &:hover,
                    &:focus {
                        color: $white;
                        background-color: $red;
                    }
                }
            }
        }

        .form {
            &-zipcode-check {
                @include clearfix;
                margin: 0 -15px $default-margin;

                @include breakpoint(sm) {
                    margin: 0 0 $default-margin;
                }
            }

            &-group {
                float: left;
                padding: 0 15px;

                @include breakpoint(sm) {
                    float: none;
                    clear: both;
                    width: 100%;
                    padding: 0;
                }

                &-column-1 {
                    width: 35%;

                    @include breakpoint(sm) {
                        width: 100%;
                    }
                }

                &-column-2 {
                    width: 20%;

                    @include breakpoint(sm) {
                        width: 100%;
                    }
                }

                &-column-3 {
                    padding-top: 32px;

                    @include breakpoint(sm) {
                        padding-top: 0;
                    }
                }

                label {
                    font-size: 14px;
                }
            }
        }
    }
}

.zipcode-check-popup {
    &--form {
        .form {
            &-zipcode-check-popup {
                width: 60%;

                @include breakpoint(sm) {
                    width: 100%;
                }
            }

            &-control {
                background-color: $white;
            }

            &-check {
                input[type="radio"],
                input[type="checkbox"] {
                    + i {
                        background-color: $white;
                    }
                }
            }

            &-group {
                label {
                    font-size: 14px;
                }
            }
        }
    }

    &--description {
        display: none;

        .cta {
            margin: 40px 0 $default-margin;

            @include breakpoint(sm) {
                margin: 0 0 $default-margin;
            }
        }
    }

    &--active {
        .zipcode-check-popup {
            &--form {
                @include opacity(.5);
            }

            &--description {
                display: block;
            }
        }
    }
}

.popup {
    &--block {
        position: absolute;
        top: 0;
        width: 100%;
        color: $white;
        padding: 70px 0;
        z-index: 9998;

        h2,
        h3 {
            color: $white;
        }
    }

    &--close {
        position: absolute;
        top: 40px;
        right: 40px;
        display: block;
        width: 40px;
        height: 40px;
        text-indent: -9999px;
        overflow: hidden;
        background: url("/assets/img/btn-icons.svg") no-repeat 0 -360px;

        @include breakpoint(sm) {
            top: 20px;
            right: 20px;
        }
    }
}

.overview {
    &--block {
        margin: 0 0 $default-margin;

        > a {
            display: block;
            color: $text-color;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    &--image {
        margin: 0 0 $default-margin;
    }

    &--date {
        margin: -$default-margin 0 $default-margin;
    }

    &--more {
        color: $customer-color;
        white-space: nowrap;
    }

    &--content {
        word-break: break-word;

        h2 {
            font-size: 34px;
            line-height: 42px;
            margin-top: -9px;

            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }
        }
    }
}

.newsletter {
    &--block {
        position: relative;
        margin: 0 0 30px;
    }

    &--image {
        @include opacity(.1);

        @include breakpoint(sm) {
            @include opacity(1);
        }
    }

    &--content {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 20px 20px 5px;

        @include breakpoint(sm) {
            position: static;
        }

        h2 {
            font-size: 36px;
            line-height: 44px;
            font-weight: 600;
        }
    }

    &--description {
        margin: -$default-margin 0 $default-margin;
        text-transform: uppercase;
    }
}

.content {
    &--list {
        margin: 0 0 $default-margin;
        padding: 0 40px;
        list-style-type: none;
        overflow: hidden;

        @include breakpoint(sm) {
            padding: 0 0 0 20px;
        }

        li {
            position: relative;

            &::after {
                @include border-radius(50%);
                position: absolute;
                top: 10px;
                left: -12px;
                display: block;
                width: 5px;
                height: 5px;
                content: "";
                background-color: $text-color;
            }

            h2,
            h3 {
                font-size: 16px;
                line-height: 24px;
                margin: 0;
            }
        }
    }

    &--gallery {
        font-size: 0;
        line-height: 0;
        text-align: center;
        padding: 0 12px;
        margin: 0 0 $default-margin;
        list-style-type: none;

        @include breakpoint(sm) {
            padding: 0;
        }

        li {
            display: inline-block;
            width: 14.2%;
            padding: 0 5px;
            margin: 0 0 $default-margin;

            @include breakpoint(sm) {
                width: 50%;
            }

            &::after {
                display: none;
            }

            a {
                display: block;
            }
        }
    }
}

.cta {
    &--block {
        overflow: hidden;
        margin: 0 0 $default-margin;

        .cta {
            margin: 40px 0 $default-margin;

            @include breakpoint(sm) {
                margin: 0 0 $default-margin;
            }
        }
    }
}

.channeloverview {
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    overflow-y: scroll;
    z-index: -1;
    background: $white;
    transition: opacity .3s;

    &.overview-page {
        position: relative;
        z-index: auto;
        overflow: auto;
        display: block;
        opacity: 1;
        height: auto;
        margin-top: 20px;

        .channeloverview__header {
            display: none;
        }

        .channeloverview__text {
            display: none;
        }
    }

    .addons-filters {
        width: 100%;

        .form-group {
            flex-wrap: wrap;
        }
    }

    .info-data {
        span {
            display: none;

            &::before {
                display: none;
            }
        }

        &:hover span {
            background: $black-transinfo;
            color: $white;
            position: absolute;
            left: 50%;
            display: block;
            width: auto;
            font-size: 12px;
            line-height: 23px;
            white-space: nowrap;
            padding: 0 5px;
            border-radius: 5px;
            bottom: -30px;
            z-index: 9999;
            transform: translateX(-50%);
            margin: 0;
            top: initial;
        }
    }

    &__container {
        position: relative;
    }

    &__overlay {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        background: $white-trans;
        top: 0;
        left: 0;
    }

    &__open {
        opacity: 1;
        z-index: 9999;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $grey-darker;
        padding: 20px;
        margin-bottom: 50px;
    }

    &__text {
        margin-bottom: 50px;
    }

    &__list {
        margin: 0 -15px 30px;

        &__header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: 15px;
            border-bottom: 1px solid $grey-darker;
            margin-bottom: 25px;

            @include breakpoint(sm) {
                justify-content: flex-start;
                padding: 0 15px;
            }

            .form-group {
                display: flex;
                min-height: 46px;
                align-items: center;
                margin: 0;

                @include media-breakpoint-down(md) {
                    display: block;
                    margin-bottom: 10px;
                }

                &:first-of-type {
                    max-width: 60%;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }
                }

                &__filter {
                    flex-wrap: wrap;
                    max-width: 100%;

                    &:first-of-type {
                        max-width: 100%;
                    }
                }

                &__label {
                    font-weight: 800;
                    margin-right: 20px;
                }

                label {
                    margin: 0 20px 0 0;
                }

                select {
                    min-width: 228px;
                    width: auto;
                }
            }
        }

        .form-check {
            margin: 0;
            flex: 1 0 33%;

            @include breakpoint(md) {
                flex: 1 0 50%;
            }

            @include breakpoint(sm) {
                flex: 1 0 100%;
            }

            label {
                width: 100%;

                input[type="checkbox"] + i {
                    background-color: $white-grey;
                    border-color: $grey-darker;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .info-data {
            top: 1px;
        }
    }

    .in-package {
        width: 17px;
        height: 17px;
        background: url("/assets/img/check-icon.svg") no-repeat center transparent;
        background-size: contain;
        display: block;
        text-indent: -9999px;
        position: relative;

        @include media-breakpoint-down(md) {
            display: inline-block;
        }
    }

    &__table {
        $self: &;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        font-size: 16px;
        line-height: normal;
        margin: 0 -15px 20px;

        &__row {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            background-color: $white-grey;

            @include media-breakpoint-down(md) {
                cursor: pointer;
            }

            &__odd {
                background-color: $white;

                +  #{ $self }__item {
                    &--description .richtext {
                        @include media-breakpoint-up(md) {
                            background-color: $white;
                        }
                    }
                }
            }
        }

        &__row--interactive &__item {
            position: relative;

            &:nth-of-type(3) {
                @include media-breakpoint-down(md) {
                    padding-right: 60px;
                    position: relative;

                    &::after {
                        @include transform(translateY(-50%));
                        content: "";
                        display: inline-block;
                        width: 30px;
                        background: transparent url("/assets/img/btn-icons.svg") 0 291px;
                        background-size: 30px;
                        height: 20px;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                    }
                }
            }
        }

        &__row--header {
            font-weight: 600;
            background-color: $white;
        }

        &__row--header &__item:nth-of-type(3) {
            &::after {
                display: none;
            }
        }

        &__row.active &__item {
            @include media-breakpoint-down(md) {
                &::after {
                    background: transparent url("/assets/img/btn-icons.svg") 0 262px;
                    background-size: 30px;
                }
            }
        }

        &__item {
            display: flex;
            flex-flow: row nowrap;
            flex-grow: 1;
            flex-basis: 0;
            padding: 15px 0;
            word-break: break-word;
            align-items: center;
            justify-content: center;

            &:nth-of-type(2) {
                flex-direction: column;
                align-items: flex-start;
            }

            &:nth-of-type(3) {
                @include media-breakpoint-down(md) {
                    padding-right: 60px;
                    position: relative;
                }
            }

            img {
                max-width: 40px;
            }

            &--description {
                .richtext {
                    padding: 10px 10px 0;
                    background: $white;
                    margin: 0;

                    @include media-breakpoint-up(md) {
                        padding: 10px 30% 10px 10%;
                        background: $white-grey;
                        border-top: 1px solid $grey-dark-border;
                    }

                    h3 {
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: 600;
                    }

                    a:not(.btn) {
                        font-weight: 600;
                        color: $text-color;
                        text-decoration: underline;
                    }
                }
            }
        }

        .hidden-device {
            opacity: 0;

            @include media-breakpoint-down(md) {
                display: none;
                -webkit-box-flex: 2;
                flex: 2 1 0;
            }
        }

        .hidden-frequency {
            display: none;
        }

        .device-content {
            display: none;
            padding: .5em;

            &__item {
                display: block;
                padding: .5em 0;
            }

            &__title {
                font-weight: 800;
                display: block;
            }
        }
    }

    select {
        appearance: none;
        user-select: none;
        width: auto;
    }

    .channel-desc {
        margin-bottom: 0;
    }

    .channel-info-data {
        border-radius: 50%;
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 5px;
        cursor: pointer;
        background: $grey-extra-dark url("/assets/img/data-info-icon.svg") no-repeat center center;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .channel__icon {
        list-style: none;
        display: flex;
        margin: 0;

        @include media-breakpoint-down(md) {
            margin-top: 5px;
        }

        &__item {
            width: 29px;
            height: 20px;
            background: url("/assets/img/icons/icon-hd.svg") no-repeat center transparent;
            background-size: contain;
            margin: 0 5px;
            position: relative;

            &:first-of-type {
                @include media-breakpoint-down(md) {
                    margin-left: 0;
                }
            }

            &--hd {
                background: url("/assets/img/icons/icon-hd.jpg") no-repeat center transparent;
                background-size: contain;
            }

            &--begingemist {
                background: url("/assets/img/icons/icon-rewind.jpg") no-repeat center transparent;
                background-size: contain;
            }

            &--terugkijken {
                background: url("/assets/img/icons/icon-ondemand.jpg") no-repeat center transparent;
                background-size: contain;
            }

            &--tvonlinethuisapp {
                background: url("/assets/img/icons/icon-tvonline.jpg") no-repeat center transparent;
                background-size: contain;
            }

            &--opnemen {
                background: url("/assets/img/icons/icon-rec.jpg") no-repeat center transparent;
                background-size: contain;
            }

            &--livetvpauzeren {
                background: url("/assets/img/icons/icon-pauze.svg") no-repeat center transparent;
                background-size: contain;
            }

            span {
                display: none;
            }

            &:hover span {
                background: $black-transinfo;
                color: $white;
                position: absolute;
                left: 50%;
                display: block;
                width: auto;
                font-size: 12px;
                line-height: 23px;
                white-space: nowrap;
                padding: 0 5px;
                border-radius: 5px;
                bottom: -30px;
                z-index: 9999;
                transform: translateX(-50%);
            }
        }
    }
}

@for $i from 1 through 10 {
    .u-flex-grow#{$i} {
        flex-grow: $i;
    }
}

[data-toggle-block] {
    [data-toggle-block-title] {
        position: relative;

        button {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border: 0;
            padding: 0;
            margin: 0;
            font-size: 0;
            background: transparent;

            &::after {
                @include icon(arrow, $black, 20px, 20px);
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 36px;
                height: 36px;
                content: "";
                transform: rotate(-90deg);
                transition: transform .3s;
            }
        }
    }

    [data-toggle-block-content] {
        display: none;
    }

    &.active {
        [data-toggle-block-title] {
            button {
                &::after {
                    transform: rotate(-270deg);
                }
            }
        }

        [data-toggle-block-content] {
            display: block;
        }
    }
}

.note {
    &--zipcode {
        border: 1px $grey-darker solid;
        line-height: 24px;
        font-size: 14px;
        padding: 7px 16px;
        border-radius: 25px;
        display: table;
        color: $grey-darker;
        margin-bottom: 20px;
    }

    &--referer {
        font-size: 12px;
        color: $grey-darker;
    }
}

.sitemap {
    li {
        font-weight: 600;

        li {
            margin-left: 25px;
            font-weight: normal;
        }
    }
}

.flow-confirm-popup {
    position: fixed;
    display: none;
    z-index: 99999;

    &.active {
        display: block;
    }

    &::before {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        opacity: .7;
        background: $grey-extra-dark;
    }

    &__wrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        display: block;
        width: 700px;
        max-width: 80%;
        color: $blue-dark;
        padding: 20px 20px 15px;
        transform: translate(-50%, -50%);
        background-color: $white-grey;
        border: 2px solid $grey-dark;
        border-radius: 4px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            padding: 20px 5px 30px;
        }

        h2 {
            color: $green;
            text-align: center;
            padding: 30px 0 15px;
            font-size: 26px;
            line-height: 36px;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                padding: 0;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .btn {
            margin: 0 10px;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
                line-height: 24px;
                height: 30px;
                padding: 3px 10px;
                margin: 0;
            }

            &--close {
                position: absolute;
                top: 10px;
                right: 20px;

                @include media-breakpoint-down(sm) {
                    top: auto;
                    bottom: 10px;
                    right: 15px;
                    padding: 0 20px 0 0;
                }
            }
        }
    }
}

.spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: $green;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    animation-delay: -.32s;
}

.spinner .bounce2 {
    animation-delay: -.16s;
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% { transform: scale(0); }
    40% { transform: scale(1); }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}
