.header {
    position: relative;
    flex-shrink: 0;
    z-index: 9998;

    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    &--search {
        display: none;
        padding: 15px 0;
        background-color: $white-grey;

        .search-form {
            position: relative;
            margin: 0;

            &-input {
                @include border-radius(40px);
                width: 100%;
                height: 40px;
                padding-right: 55px;
                background-color: $white;

                &:focus {
                    background-color: $white;
                }
            }

            &-button {
                @include border-radius(40px);
                position: absolute;
                right: 0;
                bottom: 0;
                display: block;
                width: 40px;
                height: 40px;
                padding: 0;
                border: 0;
                background: url("/assets/img/header-icons.svg") no-repeat 0 0;
                text-indent: -99999px;
            }
        }
    }

    &--content {
        display: flex;
        flex-wrap: wrap;
        padding: 18px 20px 0;
        background: $white;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    &--info {
        @include clearfix;
        margin: 0 0 0 auto;
        order: 4;

        @include media-breakpoint-down(lg) {
            order: 3;
        }

        @include media-breakpoint-down(md) {
            margin: -20px -20px 20px;
            background: $grey;
            order: -1;
        }
    }

    .logo {
        display: inline-block;
        width: 256px;
        height: 44px;
        margin: 0 15px 16px 0;
        order: 1;

        @include media-breakpoint-down(md) {
            margin: 0 auto 20px;
        }

        img {
            width: auto;
            max-height: 100%;
        }
    }

    .nav-toggle {
        display: none;
        float: right;
        cursor: pointer;

        @include media-breakpoint-down(lg) {
            display: inline-block;
        }

        &--title {
            float: left;
            display: none;
            font-size: 15px;
            line-height: 50px;
            font-weight: 600;
            color: $customer-color;
            text-transform: uppercase;
            margin: 0 10px 0 0;

            .close {
                display: none;
            }
        }
    }

    .cart {
        display: inline-block;
        margin: 0 0 16px 15px;
        order: 3;

        @include media-breakpoint-down(lg) {
            margin: 0 0 16px;
            order: 2;
        }

        @include media-breakpoint-down(md) {
            margin: 0 0 20px;
        }
    }

    .search-toggle {
        @include border-radius(50%);
        float: right;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 2px 0 20px 15px;
        text-indent: -99999px;
        background: $grey-light url("/assets/img/header-icons.svg") no-repeat 0 0;

        @include media-breakpoint-down(lg) {
            display: block;
            width: 50px;
            height: 50px;
            margin: 0;
            background-position: 5px 5px;
        }

        @include media-breakpoint-down(md) {
            background-color: transparent;
        }

        &.active {
            background-position: 0 -360px;

            @include media-breakpoint-down(lg) {
                background-position: 5px -355px;
            }
        }
    }

    .phone-shortcut {
        @include border-radius(50%);
        display: none;
        float: right;
        width: 50px;
        height: 50px;
        margin: 0;
        padding: 0;
        text-indent: -99999px;
        background: transparent url("/assets/img/header-icons.svg") no-repeat 3px -397px;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}
