.list {
    padding: 0;
    margin: 0 0 $default-margin;
    list-style-type: none;

    li {
        padding: 0;
        margin: 0;

        a {
            display: block;
            color: $customer-color;

            &:hover {
                color: $customer-color;
                text-decoration: underline;
            }
        }
    }

    &__services {
        li {
            position: relative;
            padding-left: 45px;

            &::after {
                background-size: 35px 35px;
                position: absolute;
                top: 5px;
                left: 0;
                display: block;
                width: 35px;
                height: 35px;
                content: "";
            }

            &:nth-child(1) {
                &::after {
                    background-image: url("/assets/img/services/internet.svg");
                }
            }

            &:nth-child(2) {
                &::after {
                    background-image: url("/assets/img/services/tv.svg");
                }
            }

            &:nth-child(3) {
                &::after {
                    background-image: url("/assets/img/services/phone.svg");
                }
            }
        }
    }

    &__check {
        li {
            position: relative;
            padding-left: 30px;

            &::after {
                position: absolute;
                top: 12px;
                left: 0;
                display: block;
                width: 20px;
                height: 20px;
                content: "";
                background: url("/assets/img/check-icon.svg") no-repeat center center;
            }
        }
    }

    &__contact {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        li {
            flex: 0 0 auto;
            margin: 0 15px $default-margin 0;

            @include breakpoint(sm) {
                flex: 0 0 100%;
                margin-right: 0;
            }

            a {
                position: relative;
                display: block;
                color: $customer-color;
                font-weight: 600;
                text-decoration: none;
                padding: 8px 0 8px 50px;

                &::before {
                    @include border-radius(50%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 40px;
                    height: 40px;
                    content: "";
                    background: $blue url("/assets/img/btn-icons.svg") no-repeat 0 0;
                }

                &:hover,
                &:focus {
                    color: $blue-hover;
                    text-decoration: none;

                    &::before {
                        background-color: $blue-hover;
                    }
                }
            }

            &.chat {
                a {
                    &::before {
                        background-position: 0 -40px;
                    }
                }
            }

            &.messenger {
                a {
                    &::before {
                        background-position: 0 -80px;
                    }
                }
            }

            &.mail {
                a {
                    &::before {
                        background-position: 0 -120px;
                    }
                }
            }

            &.phone {
                a {
                    &::before {
                        background-position: 0 -160px;
                    }
                }
            }

            &.call {
                a {
                    &::before {
                        background-position: 0 -480px;
                    }
                }
            }
        }
    }

    &__special {
        li {
            font-weight: bold;
        }
    }
}
