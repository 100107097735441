// scss-lint:disable ImportPath
@import "settings/vars";

// bootstrap
@import "../../libs/bootstrap-v4/scss/functions";
@import "../../libs/bootstrap-v4/scss/variables";
@import "../../libs/bootstrap-v4/scss/mixins";
@import "../../libs/bootstrap-v4/scss/print";
@import "../../libs/bootstrap-v4/scss/reboot";
@import "../../libs/bootstrap-v4/scss/images";
@import "../../libs/bootstrap-v4/scss/breadcrumb";
@import "../../libs/bootstrap-v4/scss/pagination";
@import "../../libs/bootstrap-v4/scss/progress";
@import "../../libs/bootstrap-v4/scss/grid";
@import "../../libs/bootstrap-v4/scss/forms";
@import "../../libs/bootstrap-v4/scss/utilities";
@import "../../libs/owl.carousel/dist/assets/owl.carousel.css";
@import "../../libs/owl.carousel/dist/assets/owl.theme.default.css";
@import "../../libs/nivo-lightbox/nivo-lightbox.css";
@import "../../libs/nivo-lightbox/themes/default/default.css";
@import "../../libs/jquery-ui-1.12.1.custom/jquery-ui.css";

// Mixins
@import "tools/mixin";
@import "tools/animation";

// Base
@import "base/base";
@import "base/heading";
@import "base/list";

// Layout
@import "layout/header";
@import "layout/sidebar";
@import "layout/footer";

// Components
@import "components/richtext";
@import "components/block";
@import "components/breadcrumb";
@import "components/button";
@import "components/datepicker";
@import "components/faq";
@import "components/form";
@import "components/map";
@import "components/navigation";
@import "components/navigation__slide-down";
@import "components/pagination";
@import "components/slider";
@import "components/social";
@import "components/reviews";
@import "components/checkout";

// scss-lint:enable ImportPath
