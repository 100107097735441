// Mixin //
@import "../settings/vars";

// SVG icons
@mixin icon($type, $color, $width, $height) {
    @if $type == facebook {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M15,4.3h-2.9c-0.3,0-0.7,0.5-0.7,1.1v1.8H15v3.5h-3.6v8.6H7.9v-8.6H5V7.2h2.9V5.4c0-2.6,1.9-4.6,4.3-4.6H15V4.3L15,4.3z" /></svg>');
    } @else if $type == twitter {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M19,4.3c-0.7,0.3-1.4,0.5-2.1,0.6c0.8-0.5,1.3-1.2,1.6-2.1c-0.7,0.4-1.5,0.7-2.3,0.9c-0.7-0.7-1.6-1.2-2.7-1.2c-2,0-3.7,1.7-3.7,3.9c0,0.3,0,0.6,0.1,0.9C6.8,7.1,4.1,5.5,2.3,3.2C2,3.8,1.8,4.4,1.8,5.1c0,1.3,0.5,2.5,1.5,3.2c-0.6,0-0.9-0.2-1.8-0.5l0,0c0,1.8,1.4,3.4,3.1,3.8c-0.3,0.1-0.6,0.1-0.9,0.1c-0.2,0-0.4,0-0.7-0.1c0.5,1.5,1.9,2.6,3.5,2.6c-1.3,1-2.8,1.6-4.6,1.6c-0.3,0-0.6,0-0.9-0.1c1.6,1.1,3.6,1.7,5.7,1.7c6.8,0,10.5-5.9,10.5-10.9c0-0.2,0-0.3,0-0.5C17.9,5.7,18.5,5,19,4.3z" /></svg>');
    } @else if $type == youtube {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M18.5,9.8c0-1.8-0.1-3.8-0.8-4.7c-0.6-0.9-1.5-0.9-3-0.9C12.5,4,10,3.9,7.9,4C7.1,4,6.2,4,5.4,4.1c-0.9,0-1.6,0-2.4,0.3C1.5,5,1.6,6.7,1.5,8.6c0,2.1-0.1,5.4,0.9,6.5c0.8,0.8,2.5,0.8,3.8,0.9c2.6,0.1,4.9,0.1,7.3,0c1.2,0,2.6,0,3.4-0.3C18.7,14.8,18.5,12.3,18.5,9.8z M8.3,12.2L8.2,7.4c1.5,0.8,3.1,1.5,4.6,2.3C11.3,10.6,9.7,11.4,8.3,12.2z"/></svg>');
    } @else if $type == linkedin {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><g><path class="st0" d="M5.6,17.5V7.3H2.2v10.2H5.6L5.6,17.5z M3.9,5.9c1.2,0,1.9-0.8,1.9-1.7c0-1-0.7-1.7-1.9-1.7S2,3.3,2,4.2S2.7,5.9,3.9,5.9L3.9,5.9L3.9,5.9z"/><path class="st0" d="M7.5,17.5H11v-5.7c0-0.3,0-0.6,0.1-0.8c0.2-0.6,0.8-1.2,1.8-1.2c1.2,0,1.7,0.9,1.7,2.3v5.4H18v-5.8C18,8.5,16.3,7,14.1,7c-1.9,0-2.7,1-3.1,1.7l0,0V7.2H7.6C7.6,8.2,7.5,17.5,7.5,17.5L7.5,17.5z"/></g></svg>');
    } @else if $type == instagram {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';} </style><g><path class="st0" d="M15.4,5.7c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1s0.5-1,1-1C14.9,4.6,15.4,5.1,15.4,5.7"/><g><path class="st0" d="M18.4,6.5c0-0.9-0.2-1.5-0.4-2.1c-0.2-0.6-0.5-1-0.9-1.5c-0.5-0.5-0.9-0.8-1.5-0.9c-0.6-0.2-1.1-0.4-2.1-0.4c-0.8-0.1-1.2-0.1-3.5-0.1c-2.3,0-2.6,0-3.5,0.1C5.6,1.6,5,1.8,4.4,2c-0.6,0.2-1,0.5-1.5,0.9S2.2,3.9,2,4.4C1.8,5,1.6,5.6,1.6,6.5S1.5,7.7,1.5,10s0,2.6,0.1,3.5c0,0.9,0.2,1.5,0.4,2.1c0.2,0.6,0.5,1,0.9,1.5c0.5,0.5,0.9,0.8,1.5,0.9c0.6,0.2,1.1,0.4,2.1,0.4s1.2,0.1,3.5,0.1s2.6,0,3.5-0.1c0.9,0,1.5-0.2,2.1-0.4c0.6-0.2,1-0.5,1.5-0.9c0.5-0.5,0.8-0.9,0.9-1.5c0.2-0.6,0.4-1.1,0.4-2.1c0-0.9,0.1-1.2,0.1-3.5C18.5,7.7,18.5,7.4,18.4,6.5z M16.9,13.4c0,0.8-0.2,1.3-0.3,1.6c-0.2,0.4-0.4,0.7-0.7,0.9c-0.3,0.3-0.6,0.5-0.9,0.7c-0.3,0.1-0.8,0.3-1.6,0.3c-0.9,0-1.1,0.1-3.4,0.1s-2.5,0-3.4-0.1c-0.8,0-1.3-0.2-1.6-0.3C4.6,16.4,4.3,16.2,4,16c-0.3-0.3-0.5-0.6-0.7-0.9c-0.1-0.3-0.3-0.8-0.3-1.6C3.1,12.6,3,12.3,3,10c0-2.3,0-2.5,0.1-3.4c0-0.8,0.2-1.3,0.3-1.6C3.6,4.6,3.8,4.3,4,4S4.6,3.6,5,3.4c0.3-0.1,0.8-0.3,1.6-0.3C7.4,3,7.7,3,10,3s2.6,0,3.4,0.1c0.8,0,1.3,0.2,1.6,0.3c0.4,0.2,0.7,0.4,0.9,0.7c0.3,0.3,0.5,0.6,0.7,0.9c0.1,0.3,0.3,0.8,0.3,1.6S17,7.7,17,10S17,12.6,16.9,13.4z"/><path class="st0" d="M10,5.7c-2.5,0-4.3,2-4.3,4.3c0,2.5,2,4.3,4.3,4.3c2.5,0,4.3-2,4.3-4.3C14.3,7.5,12.5,5.7,10,5.7 M10,12.8c-1.6,0-2.8-1.2-2.8-2.8S8.4,7.2,10,7.2s2.8,1.2,2.8,2.8S11.6,12.8,10,12.8"/></g></g></svg>');
    } @else if $type == googleplus {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><g><g><polygon class="st0" points="18.5,9.3 16.1,9.3 16.1,6.9 15,6.9 15,9.3 12.7,9.3 12.7,10.3 15,10.3 15,12.7 16.1,12.7 16.1,10.3 18.5,10.3"/></g><g><path class="st0" d="M10.2,11c-0.5-0.4-1.5-1-1.5-1.5c0-0.6,0.2-0.8,1-1.5c0.9-0.7,1.5-1.6,1.5-2.6c0-1.2-0.5-2.3-1.4-2.8h1.3l1.1-1c0,0-3.7,0-5,0c-2.6,0-4.7,1.9-4.7,3.9s1.4,3.8,3.8,3.8c0.2,0,0.3,0,0.5,0c-0.2,0.3-0.3,0.7-0.3,0.9c0,0.6,0.3,0.9,0.8,1.3c-0.3,0-0.6,0-0.9,0c-2.9,0-4.9,2-4.9,3.9c0,1.9,2.5,3.1,5.4,3.1c3.3,0,5.4-1.9,5.4-3.8C12.2,13,11.9,12.2,10.2,11z M7.2,8.4c-1.3,0-2.7-1.3-2.8-3C4.2,3.6,5,2.3,6.3,2.3s2.6,1.5,2.8,3.3C9.4,7.2,8.6,8.4,7.2,8.4z M6.7,17.4c-2,0-3.5-1.2-3.5-2.8c0-1.5,1.7-2.8,3.7-2.7c0.5,0,0.9,0.1,1.3,0.2c1.1,0.8,2,1.2,2.2,2.1c0,0.2,0.1,0.4,0.1,0.6C10.6,16.2,9.6,17.4,6.7,17.4z"/></g></g></svg>');
    } @else if $type == pinterest {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M9.4,13.8c1.5,1.1,4.2,0.5,5.3-0.6c1.7-1.4,2.4-4.3,2.3-6.6c-0.3-2.5-2.7-4.8-5.5-5.1C7,1.1,3,3.7,3,8c0,2,0.7,3.2,2.4,4c1.2-1.2-0.2-2-0.4-3.3C4.7,5,9,1.6,12.8,3.9c2.6,1.5,2.2,8.1-1,8.8c-0.7,0.1-1.4-0.1-1.8-0.4c-1.5-1.4,1.3-3.9,0.4-5.9C9.6,4.6,7.3,6,7,7.6C6.9,8.4,7.3,9.2,7.3,9.9c0,1.7-1.1,4.1-1.4,5.8c-0.1,0.7-0.2,2.1,0,2.7l0,0H7c0.8-1.3,1.4-3.4,1.8-5.2C9.1,13.1,9.2,13.7,9.4,13.8z"/></svg>');
    } @else if $type == whatsapp {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><g><path class="st0" d="M18.5,9.8c0,4.6-3.7,8.3-8.3,8.3c-1.5,0-2.8-0.4-4-1l-4.6,1.5L3,14.1c-0.8-1.2-1.2-2.7-1.2-4.3c0-4.6,3.7-8.3,8.3-8.3C14.8,1.5,18.5,5.2,18.5,9.8z M10.2,2.8c-3.9,0-7,3.1-7,7c0,1.5,0.5,2.9,1.3,4.1l-0.9,2.6l2.7-0.9c1.1,0.7,2.4,1.2,3.9,1.2c3.9,0,7-3.1,7-7S14,2.8,10.2,2.8z M14.4,11.7c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2-0.1-1.2-0.6-1.4-0.7c-0.2-0.1-0.3-0.1-0.5,0.1c-0.1,0.2-0.5,0.7-0.6,0.8c-0.1,0.1-0.2,0.2-0.4,0.1c-0.2-0.1-0.9-0.3-1.6-1c-0.6-0.5-1-1.2-1.1-1.4C8.1,9.1,8.2,9,8.3,8.9c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0-0.3,0-0.4C8.8,7.8,8.4,6.8,8.2,6.4C8,6,7.9,6,7.7,6C7.6,6,7.5,6,7.3,6S7,6.1,6.8,6.3C6.6,6.5,6.1,6.9,6.1,7.9c0,1,0.7,2,0.8,2.1c0.1,0.1,1.4,2.3,3.5,3.1c2.1,0.8,2.1,0.5,2.5,0.5c0.4,0,1.2-0.5,1.4-1C14.4,12.2,14.4,11.8,14.4,11.7z"/></g></svg>');
    } @else if $type == newsletter {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><g><g><path class="st0" d="M1,17c0-4.6,0-9.4,0-14c6,0,12,0,18,0c0,4.6,0,9.4,0,14C13,17,7,17,1,17z M17.4,4.1c-5,0-9.9,0-14.8,0c2.5,2.5,5,5.1,7.4,7.7C12.5,9.3,14.9,6.8,17.4,4.1z M7.4,10.9c-1.6,1.7-3.3,3.3-4.8,5c4.9,0,9.9,0,14.7,0c-1.6-1.7-3.3-3.3-4.8-5c-0.9,0.9-1.7,1.8-2.6,2.6C9.1,12.6,8.3,11.8,7.4,10.9z M6.6,10c-1.5-1.6-3-3.1-4.5-4.6c0,3.1,0,6.2,0,9.3C3.6,13.1,5.1,11.6,6.6,10z M17.9,5.4c-1.5,1.6-3,3.1-4.5,4.6c1.5,1.6,3,3.1,4.5,4.6C17.9,11.6,17.9,8.4,17.9,5.4z"/></g></g></svg>');
    } @else if $type == email {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><g><g><path class="st0" d="M1,17c0-4.6,0-9.4,0-14c6,0,12,0,18,0c0,4.6,0,9.4,0,14C13,17,7,17,1,17z M17.4,4.1c-5,0-9.9,0-14.8,0c2.5,2.5,5,5.1,7.4,7.7C12.5,9.3,14.9,6.8,17.4,4.1z M7.4,10.9c-1.6,1.7-3.3,3.3-4.8,5c4.9,0,9.9,0,14.7,0c-1.6-1.7-3.3-3.3-4.8-5c-0.9,0.9-1.7,1.8-2.6,2.6C9.1,12.6,8.3,11.8,7.4,10.9z M6.6,10c-1.5-1.6-3-3.1-4.5-4.6c0,3.1,0,6.2,0,9.3C3.6,13.1,5.1,11.6,6.6,10z M17.9,5.4c-1.5,1.6-3,3.1-4.5,4.6c1.5,1.6,3,3.1,4.5,4.6C17.9,11.6,17.9,8.4,17.9,5.4z"/></g></g></svg>');
    } @else if $type == phone {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><g><g><path class="st0" d="M18.3,15.4L15,12.2c-0.3-0.3-0.7-0.3-1,0l-1.2,1.1l4.2,4.2l1.2-1.1C18.6,16.1,18.6,15.7,18.3,15.4z M7.7,5.9c0.3-0.3,0.3-0.7,0-1L4.4,1.7c-0.3-0.3-0.7-0.3-1,0L2.3,2.8l4.2,4.2L7.7,5.9z M12.2,14c-0.4,0.4-1.2,0.2-1.5-0.2L6,9.3C5.7,9,5.4,8.2,5.7,7.8l0,0L1.6,3.7C1,7.1,2.4,10.9,5.7,14.3c3.3,3.3,7.3,4.7,10.6,4.1L12.2,14L12.2,14z"/></g></g></svg>');
    } @else if $type == search {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M18.1,16.1L14,12c-0.1-0.1-0.1-0.1-0.2-0.2c0.7-1.1,1.1-2.3,1.1-3.6c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7c1.4,0,2.6-0.4,3.6-1.1c0,0.1,0.1,0.1,0.2,0.2l4.1,4.1c0.3,0.3,0.6,0.4,1,0.4s0.7-0.2,1-0.4C18.6,17.5,18.6,16.6,18.1,16.1 M8.2,12.9c-2.5,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6s4.6,2.1,4.6,4.6S10.8,12.9,8.2,12.9"/></svg>');
    } @else if $type == share {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M8.7,9.9c0,0.4-0.1,0.8-0.3,1.3l3.4,2.1c1.3-1.4,3.4-1.5,4.8-0.3c1.4,1.3,1.5,3.4,0.3,4.8c-1.3,1.4-3.4,1.5-4.8,0.3c-1.1-0.9-1.4-2.4-0.8-3.6l-3.4-2.1c-1.3,1.4-3.5,1.4-4.9,0.1S1.7,9,3,7.7c1.3-1.4,3.5-1.4,4.9-0.1l0.1,0.1l3.4-2.1c-0.7-1.9,0.3-3.7,2.1-4.4s3.7,0.3,4.4,2.1c0.7,1.9-0.3,3.7-2.1,4.4c-1.3,0.4-2.8,0.1-3.7-0.9L8.7,8.9C8.5,9,8.7,9.4,8.7,9.9z"/></svg>');
    } @else if $type == close {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M18,4.6L15.6,2L10,7.5l0,0l0,0L4.4,2L2,4.6L7.5,10L2,15.4L4.4,18l5.6-5.5l0,0l0,0l5.6,5.5l2.4-2.6L12.5,10L18,4.6z"/></svg>');
    } @else if $type == arrow {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M12.6,17.6c0.5,0.6,0.5,1.5-0.1,2l0,0c-0.6,0.5-1.5,0.5-2-0.1l-7.1-8.4c-0.5-0.5-0.5-1.4,0-1.9l7-8.7c0.5-0.6,1.5-0.6,2-0.1c0.6,0.5,0.9,1.4,0.4,2l0,0l-6.3,7.7L12.6,17.6z"/></svg>');
    } @else if $type == selecticon {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 6" style="enable-background:new 0 0 20 6;" xml:space="preserve"> <style type="text/css">.st0{fill:' + $color + ';}</style><path id="Shape_3_copy_3" class="st0" d="M10.6,0.2c0.4-0.3,0.9-0.3,1.2,0.1l0,0c0.3,0.4,0.3,0.9-0.1,1.2l-5,4.3 c-0.3,0.3-0.8,0.3-1.1,0L0.3,1.6c-0.4-0.3-0.4-0.9-0.1-1.2C0.5,0,1-0.1,1.4,0.2l0,0L6.1,4L10.6,0.2z"/></svg>');
    } @else if $type == chat {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M24.6,47.8c-3.2,0-5.8,2.6-5.8,5.9c0,3.2,2.6,5.8,5.9,5.8c1.1,0,2.2-0.3,3.2-1l2.3,0.7L29.5,57c0.7-1,1.1-2.2,1.1-3.4C30.4,50.4,27.8,47.8,24.6,47.8z M24.5,57c-0.3,0-0.6-0.3-0.7-0.6c0-0.3,0.3-0.6,0.6-0.7c0.3,0,0.6,0.3,0.7,0.6l0,0C25.2,56.7,24.9,57,24.5,57C24.6,57,24.6,57,24.5,57z M25.5,53.2c-0.4,0.4-0.6,0.9-0.6,1.5V55h-0.7v-0.3c0-0.6,0.2-1.3,0.6-1.7s0.6-0.8,0.7-1.4c0-0.5-0.3-0.9-0.8-0.9c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.1-1,0.3l-0.2-0.6c0.4-0.3,0.9-0.4,1.4-0.4c1.2,0,1.7,0.7,1.7,1.5C26.4,52.2,26,52.7,25.5,53.2z M15.5,60.1c-3.2,0-5.9,2.6-5.9,5.8c0,1.2,0.4,2.4,1.1,3.4L10,71.5l2.3-0.7c2.7,1.8,6.3,1.1,8.1-1.6c1.8-2.7,1.1-6.3-1.6-8.1C17.9,60.5,16.7,60.1,15.5,60.1zM13.5,66.8c-0.3,0-0.6-0.3-0.7-0.6c0-0.3,0.3-0.6,0.6-0.7c0.3,0,0.6,0.3,0.7,0.6l0,0C14.1,66.5,13.9,66.8,13.5,66.8L13.5,66.8zM15.6,66.8c-0.3,0-0.6-0.3-0.6-0.7c0-0.3,0.3-0.6,0.6-0.6s0.6,0.2,0.6,0.6c0,0,0,0,0,0.1C16.2,66.5,15.9,66.8,15.6,66.8L15.6,66.8L15.6,66.8z M17.6,66.8c-0.3,0-0.6-0.3-0.6-0.7c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6c0,0,0,0,0,0.1C18.2,66.5,18,66.8,17.6,66.8C17.7,66.8,17.6,66.8,17.6,66.8L17.6,66.8z M15.5,73.1c-1.2,0-2.4-0.3-3.5-0.9l-4,1.3l1.3-3.8c-0.7-1.1-1-2.4-1-3.7c0-4,3.2-7.2,7.1-7.3c4,0,7.2,3.2,7.2,7.1C22.7,69.8,19.6,73.1,15.5,73.1C15.6,73.1,15.6,73.1,15.5,73.1zM28,59.9c-3.4,1.9-7.8,0.6-9.6-2.8c-1.9-3.5-0.6-7.8,2.8-9.7s7.8-0.6,9.6,2.8c0.6,1,0.9,2.2,0.9,3.4c0,1.3-0.4,2.6-1,3.7l1.3,3.8L28,59.9z"/></svg>');
    }
    background-repeat: no-repeat;
    background-position: center center;
}

// SVG icons
@function svg-uri($svg) {
    $encoded: "";
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        $chunk: str-replace($chunk, '"', "'");
        $chunk: str-replace($chunk, "<", "%3C");
        $chunk: str-replace($chunk, ">", "%3E");
        $chunk: str-replace($chunk, "&", "%26");
        $chunk: str-replace($chunk, "#", "%23");
        $chunk: str-replace($chunk, "{", "%7B");
        $chunk: str-replace($chunk, "}", "%7D");
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

// Media sizes
@mixin breakpoint($point) {
    @if $point == xl {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $point == lg {
        @media (min-width: 992px) and (max-width: 1199px) {
            @content;
        }
    } @else if $point == md {
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }
    } @else if $point == sm {
        @media (max-width: 767px) {
            @content;
        }
    } @else if $point == xs {
        @media (max-width: 575px) {
            @content;
        }
    } @else if $point == smalllaptop {
        @media (min-width: 1192px) and (max-width: 1338px) {
            @content;
        }
    } @else if $point == articlesmall {
        @media (min-width: 1200px) and (max-width: 1440px) {
            @content;
        }
    }
}

// Centering object
@mixin vertical-align() {
    display: block;

    &::before {
        content: "";
        display: inline-block;
        height: 100%;
        margin-right: -.25em;
        vertical-align: middle;
    }

    > * {
        display: inline-block;
        vertical-align: middle;
        width: 98%;
    }
}

// Transform
@mixin transform($args...) {
    transform: $args;
}

// Greyscale filter
@mixin grayscale($grayscale) {
    filter: #{ "grayscale(" + $grayscale + ")" };
}

// Box shadow
@mixin box-shadow($shadow) {
    box-shadow: $shadow;
}

// Opacity

// #6 http://zerosixthree.se/8-sass-mixins-you-must-have-in-your-toolbox/
@mixin opacity($opacity) {
    opacity: $opacity;
    filter: alpha(opacity = $opacity * 100);
}

// Horizontal gradient
@mixin gradient-hr($start, $end) {
    //background: $start;

    //background: -moz-linear-gradient(left, $start 0%, $end 100%);

    //background: -webkit-linear-gradient(left, $start 0%, $end 100%);

    //background: linear-gradient(to right, $start 0%, $end 100%);

    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='' + $start + '', endColorstr='' + $end + '', GradientType=1);
    background: linear-gradient(to right, $start 0%, $end 100%);
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr="" + $start + "", endcolorstr="" + $end + "", gradienttype=1);
}
