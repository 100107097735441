.footer {
    position: relative;
    flex-shrink: 0;

    &--top {
        padding: 50px 0 35px;
        font-size: 14px;
        line-height: 22px;
        background: $grey;

        h4 {
            font-size: 14px;
            line-height: 22px;
            padding: 3px 0;
            margin: 0;

            @include media-breakpoint-down(sm) {
                padding: 0;
                margin: 0 0 $default-margin;
            }

            a {
                display: block;
                color: $text-color;

                &:hover,
                &:focus {
                    color: $text-color;
                }
            }
        }

        .list {
            @include media-breakpoint-down(sm) {
                display: none;
            }

            li {
                a {
                    color: $text-color;
                    padding: 3px 0;
                    text-decoration: none;

                    &:hover {
                        color: $text-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &--bottom {
        @include clearfix;
        @include gradient-hr($blue-gradient-1, $blue-gradient-2);
        padding: 15px 20px 0;
        color: $white;
        font-size: 14px;
        line-height: 22px;
        overflow: hidden;

        @include breakpoint(md) {
            text-align: center;
            padding: 15px 15px 0;
        }

        @include breakpoint(sm) {
            text-align: center;
            padding: 15px 15px 0;
        }
    }

    &--block {
        h4 {
            padding: 0;
            margin: 0 0 $default-margin;
        }
    }

    &--copyright {
        float: left;
        font-size: 14px;
        margin: 4px 0 19px;

        @include breakpoint(sm) {
            float: none;
            clear: both;
            display: block;
        }

        .name {
            text-transform: uppercase;
        }
    }
}

.app-block {
    position: fixed;
    bottom: 20px;
    right: 50px;
    width: 250px;
    height: 330px;
    background-color: $white;
    border-radius: 20px;
    overflow: hidden;
    z-index: 99;
    display: none;

    &_close {
        position: absolute;
        left: 15px;
        font-weight: bold;
        font-size: 20px;
        color: $white;
    }

    &_open {
        @include icon("whatsapp", $white, 35px, 35px);
        background-color: $whatsapp-color;
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 50px;
        right: 50px;
        border-radius: 100%;
        z-index: 99;
        display: none;

        &.desktop {
            @include breakpoint(sm) {
                display: none;
            }
        }
    }

    &--header {
        background-color: $whatsapp-color;
        width: 100%;
        padding: 10px 0 10px 45px;
        color: $white;
        font-weight: bold;

        span {
            width: 100%;
            display: block;

            &.subtitle {
                font-weight: normal;
            }
        }
    }

    &--content {
        text-align: center;
        font-size: 14px;

        .qr {
            width: 150px;
        }

        p {
            font-size: 14px;
            padding: 0 25px;
        }
    }
}
