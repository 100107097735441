// Other styling
*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}

html,
body {
    font-family: $text-font;
    color: $text-color;
    font-weight: 400;
    font-size: $text-size;
    line-height: 28px;
    min-height: 100%;
    background: $white;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
}

body {
    display: flex;
    flex-direction: column;

    &.home {
        background: $white-grey;
    }

    &.channeloverview-active {
        overflow: hidden;
    }
}

iframe {
    max-width: 100%;
    display: block;
    border: 0;
}

strong {
    font-weight: 600;
}

a {
    color: $customer-color;
    outline: none;

    &:hover,
    &:focus {
        outline: none;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

p,
blockquote,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
section,
aside,
article,
dl,
hr {
    margin: 0 0 $default-margin;
}

h1 {
    + .date {
        margin-top: -23px;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
}

ul {
    padding: 0;
    list-style-type: none;
}

.wrap {
    min-height: 100%;
    height: auto;
}

section {
    &.main {
        -webkit-box-flex: 1;
        flex: 1 0 auto;
        margin: 0;
    }
}

.wrapper {
    @extend .container;
}

.hidden-mobile {
    @include breakpoint(sm) {
        display: none;
    }
}

.visible-tablet {
    display: none;

    @include breakpoint(md) {
        display: block;
    }
}

.visible-mobile {
    display: none;

    @include breakpoint(sm) {
        display: block;
    }
}

.main-image {
    margin: -120px -15px 30px;

    @include breakpoint(sm) {
        margin: 0 -15px 30px;
    }
}

.website-wrapper {
    position: relative;
    min-height: 100vh;
    display: box;
    display: flexbox;
    display: flex;
    display: flex;
    -webkit-box-orient: vertical;
    flex-direction: column;

    &::after {
        @include transition(opacity 250ms ease-out);
        @include opacity(0);
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: none;
        content: "";
        background: $black-trans;
        z-index: 999;
    }
}

.popup-active,
.overlay-active {
    .website-wrapper {
        &::after {
            @include opacity(1);
            display: block;
        }
    }
}

.overlay-active-hover {
    &:not(.overlay-active) {
        .website-wrapper {
            &::after {
                @include opacity(1);
                display: block;

                @include breakpoint(md) {
                    display: none;
                }

                @include breakpoint(sm) {
                    display: none;
                }
            }
        }
    }
}

.sisea-paging {
    margin-bottom: 30px;
}

.modal-open {
    overflow: hidden;
}
