form {
    label {
        display: block;
        cursor: pointer;
        margin: 0;
    }

    select {
        appearance: none;
        user-select: none;
    }

    .help-block {
        margin: 8px 0 0;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

select.form-control:not([size], [multiple]) { // scss-lint:disable QualifyingElement
    height: calc(2.25rem + 4px);
}

button {
    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: .65;
        box-shadow: none;
    }
}

.form {
    &-control {
        @include border-radius(23px);
        height: 46px;
        padding: 9px 20px;
        color: $grey-extra-dark;
        background: $grey-light-3;
        border: 1px solid $grey-extra-dark-border;

        &:focus {
            color: $grey-extra-dark;
            background-color: $grey-light-3;
        }

        &:disabled,
        &:disabled:focus {
            background-color: $grey;
            border: 1px solid transparent;
            color: $grey-extra-dark;
        }

        &[readonly],
        &[readonly]:focus {
            color: $grey-extra-dark;
            background-color: $grey-light-3;
            border-color: $grey-extra-dark-border;
        }

        &__textarea {
            height: 120px;

            &-big {
                height: 240px;
            }
        }

        &__select {
            background: $grey url("/assets/img/select-icon.svg") no-repeat center right 15px;
        }
    }

    &[data-validate] {
        label {
            &.error {
                color: $red;
                padding: 10px 5px;
                font-weight: 400;
            }
        }

        .form-control {
            &.valid {
                background: $grey-light-3 url("/assets/img/check-icon.svg") no-repeat center right 15px;

                &:blank {
                    background-color: $grey-light-3;
                }
            }
        }
    }

    &-check {
        margin: 0 0 $default-margin;

        &__column {
            display: inline-block;
            margin-right: $default-margin;
        }

        label {
            display: inline-block;
            position: relative;
            padding: 0 0 0 25px;

            input[type="radio"],
            input[type="checkbox"] {
                display: none;

                + i {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border: 1px solid $grey-extra-dark-border;

                    &::before {
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        display: none;
                        width: 12px;
                        height: 12px;
                        content: "";
                        background: $green;
                    }
                }

                &:checked {
                    + i {
                        &::before {
                            display: block;
                        }
                    }
                }

                &:disabled,
                &[readonly] {
                    + i {
                        &::before {
                            background-color: $grey-dark-light;
                        }
                    }
                }
            }

            input[type="radio"] {
                + i {
                    @include border-radius(50%);

                    &::before {
                        @include border-radius(50%);
                    }
                }
            }
        }

        &__checkbox {
            label {
                input[type="radio"],
                input[type="checkbox"] {
                    + i {
                        @include border-radius(0);

                        &::before {
                            @include border-radius(0);
                        }
                    }
                }
            }
        }

        &__radio {
            label {
                input[type="radio"],
                input[type="checkbox"] {
                    + i {
                        @include border-radius(50%);

                        &::before {
                            @include border-radius(50%);
                        }
                    }
                }
            }
        }

        &__small {
            label {
                font-size: 14px;

                input[type="radio"],
                input[type="checkbox"] {
                    + i {
                        width: 12px;
                        height: 12px;
                        margin: -1px 6px -1px 0;

                        &::before {
                            top: 2px;
                            left: 2px;
                            width: 8px;
                            height: 8px;
                        }
                    }
                }
            }
        }
    }

    &-check-multiple {
        &__container {
            position: relative;
            width: 90px;
            margin: 8px auto;
            cursor: pointer;
            z-index: 0;

            input[type="radio"],
            input[type="checkbox"] {
                position: absolute;
                display: block !important; // scss-lint:disable ImportantRule
                width: 30px;
                height: 30px;
                opacity: 0;
                z-index: 1;
                cursor: pointer;

                &:nth-child(1) {
                    left: 0;

                    &:checked {
                        ~ i {
                            background: $red;
                            border: 1px solid $grey-extra-dark-border;

                            &::after {
                                left: 2px;
                            }
                        }
                    }

                    &:hover {
                        ~ .data-title {
                            left: 15px;
                            display: block;
                        }
                    }
                }

                &:nth-child(2) {
                    left: 30px;

                    &:checked {
                        ~ i {
                            background: $grey-light-3;
                            border: 1px solid $grey-extra-dark-border;

                            &::after {
                                left: 32px;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    left: 60px;

                    &:checked {
                        ~ i {
                            background: $green;
                            border: 1px solid $grey-extra-dark-border;

                            &::after {
                                left: 62px;
                            }
                        }
                    }

                    &:hover {
                        ~ .data-title {
                            left: 75px;
                            display: block;
                        }
                    }
                }
            }

            i {
                @include border-radius(18px);
                position: relative;
                display: block;
                width: 92px;
                height: 32px;
                background: $grey-light-3;
                transition: background .3s;

                &::after {
                    @include border-radius(16px);
                    position: absolute;
                    top: 2px;
                    left: 32px;
                    display: block;
                    width: 26px;
                    height: 26px;
                    content: "";
                    background: $white;
                    transition: left .3s;
                    border: 1px solid $grey-extra-dark-border;
                }
            }

            .data-title {
                position: absolute;
                left: 0;
                bottom: -30px;
                display: none;
                width: auto;
                color: $white;
                font-size: 12px;
                line-height: 23px;
                white-space: nowrap;
                padding: 0 5px;
                border-radius: 5px;
                background: $black-transinfo;
                z-index: 9999;
                transform: translateX(-50%);
            }
        }

        &.small {
            position: relative;
            padding: 0 0 0 63px;

            .form-check-multiple {
                &__container {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 56px;
                    margin: 0;

                    input[type="radio"],
                    input[type="checkbox"] {
                        width: 18px;
                        height: 18px;

                        &:nth-child(1) {
                            left: 0;

                            &:checked ~ i::after {
                                left: 2px;
                            }

                            &:hover ~ .data-title {
                                left: 9px;
                                display: block;
                            }
                        }

                        &:nth-child(2) {
                            left: 18px;

                            &:checked ~ i::after {
                                left: 20px;
                            }
                        }

                        &:nth-child(3) {
                            left: 36px;

                            &:checked ~ i::after {
                                left: 38px;
                            }

                            &:hover ~ .data-title {
                                left: 45px;
                                display: block;
                            }
                        }
                    }

                    i {
                        width: 56px;
                        height: 20px;

                        &::after {
                            left: 20px;
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
        }
    }

    &-group {
        margin: 0 0 $default-margin;

        > label {
            font-weight: 600;
            margin: 0 0 8px;
        }

        ul {
            padding: 0 0 0 20px;
            list-style-type: disc;
            margin: 0;
        }

        &.has-error {
            .error {
                border-color: $red;
            }

            .help-block {
                color: $red;

                span {
                    width: 45vw;
                    display: inline-block;

                    @include media-breakpoint-down(sm) {
                        width: 90vw;
                    }
                }
            }
        }
    }

    &-columns {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .form {
            &-column {
                flex: 0 0 1;
                padding: 0 10px;
            }
        }
    }

    &-description {
        margin: 8px 0 0;
        color: $grey-dark;
        font-size: 14px;
        font-style: italic;
    }

    &-category {
        margin: 0 0 $default-margin;
        border-bottom: 1px solid $grey;

        &:last-child {
            border-bottom: 0;
        }
    }

    &-horizontal {
        .form {
            &-group {
                @include clearfix;

                > label {
                    float: left;
                    width: 35%;
                    margin: 0;
                    padding: 11px 15px 11px 0;

                    @include breakpoint(sm) {
                        float: none;
                        clear: both;
                        width: 100%;
                        padding: 0;
                        margin: 0 0 $default-margin;
                    }
                }
            }

            &-control-wrapper {
                float: left;
                width: 65%;

                @include breakpoint(sm) {
                    float: none;
                    clear: both;
                    width: 100%;
                }

                .form-check {
                    &:first-of-type {
                        margin-top: 11px;
                    }

                    &__column {
                        margin: 11px $default-margin 11px 0;

                        @include breakpoint(sm) {
                            display: block;
                            margin: 0 0 $default-margin;
                        }
                    }
                }
            }
        }
    }

    &-contact {
        h2 {
            color: $blue;
            margin: 0 0 30px;
        }

        .form-description {
            color: $grey-light-2;
        }
    }
}

// Zipcode Check

.form-zipcode {
    margin: 0;
    text-align: left;

    .form {
        &-group {
            > label {
                font-weight: 400;
                font-size: 14px;

                @include media-breakpoint-down(sm) {
                    color: $black;
                }
            }
        }

        &-columns {
            justify-content: center;
            align-items: flex-end;
        }

        &-column {
            padding: 0 10px;

            &__zipcode {
                max-width: 150px;

                @include breakpoint(sm) {
                    min-width: 100%;
                    max-width: none;
                }
            }

            &__number {
                max-width: 125px;

                @include breakpoint(sm) {
                    min-width: 100%;
                    max-width: none;
                }
            }

            &__addition {
                max-width: 100px;

                @include breakpoint(sm) {
                    min-width: 100%;
                    max-width: none;
                }
            }

            &__room {
                max-width: 100px;

                @include breakpoint(sm) {
                    min-width: 100%;
                    max-width: none;
                }
            }

            &__button {
                flex: 0 0 auto;
                padding-top: 30px;

                @include breakpoint(sm) {
                    flex: 1;
                    padding-top: 0;
                }

                .btn {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

.cpn-error {
    color: $red;
}

// Webkit
::input-placeholder {
    color: $grey-darker;
}

// Firefox 4-18
::placeholder {
    color: $grey-darker;
}

// IE10+
:input-placeholder {
    color: $grey-darker;
}

select::-ms-expand {
    display: none;
}

select option[disabled] {
    display: none;
}

::selection {
    background: $blue-hover;
}
