.faq {
    position: relative;

    &.loading {
        &::after {
            color: $orange-trans;
            content: " ";
            font-size: 90px;
            text-indent: -9999em;
            overflow: hidden;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            margin: 72px auto;
            position: fixed;
            top: 45%;
            left: 45%;
            transform: translateZ(0);
            animation: load6 1.7s infinite ease, round 1.7s infinite ease;
        }

        .faq {
            &--results {
                opacity: .5;
            }
        }
    }

    &--search {
        position: relative;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 46px;
            height: 46px;
            content: "";
            background: url("/assets/img/header-icons.svg") no-repeat 0 2px;
        }

        .form-control {
            padding-right: 37px;

            @include media-breakpoint-down(sm) {
                font-size: 13px;
            }
        }
    }

    h3 {
        color: $customer-color;
        font-size: 16px;
    }

    &--noresults {
        background: $grey;
        margin-bottom: 30px;
        padding: 10px;
    }

    &--category {
        margin: 0 0 60px;

        @include media-breakpoint-down(md) {
            margin: 0 0 40px;
        }
    }

    &--overview {
        margin: 0;
    }

    &--question {
        &__title {
            h4 {
                font-size: 16px;
                font-weight: 600;

                a {
                    color: $black;
                    display: block;
                    padding: 8px 0 8px 25px;
                    text-decoration: none;
                    border-bottom: 1px solid $grey-light;

                    &:hover,
                    &:active {
                        text-decoration: none;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 16px;
                        display: block;
                        width: 14px;
                        height: 14px;
                        background: url("/assets/img/btn-icons.svg") no-repeat -13px -293px;
                    }
                }
            }
        }

        &__content {
            display: none;
            padding: 0 15px 0 35px;
            overflow: hidden;

            ul {
                list-style-type: disc;
                margin: 0 0 15px;
                padding: 0 0 0 20px;
            }

            ol {
                margin: 0 0 15px;
                padding: 0 0 0 20px;
            }
        }

        &.active {
            .faq {
                &--question {
                    &__title {
                        h4 {
                            a::before {
                                background-position: -13px -333px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
    }

    5%,
    95% {
        box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
    }

    10%,
    59% {
        box-shadow: 0 -.83em 0 -.4em, -.087em -.825em 0 -.42em, -.173em -.812em 0 -.44em, -.256em -.789em 0 -.46em, -.297em -.775em 0 -.477em;
    }

    20% {
        box-shadow: 0 -.83em 0 -.4em, -.338em -.758em 0 -.42em, -.555em -.617em 0 -.44em, -.671em -.488em 0 -.46em, -.749em -.34em 0 -.477em;
    }

    38% {
        box-shadow: 0 -.83em 0 -.4em, -.377em -.74em 0 -.42em, -.645em -.522em 0 -.44em, -.775em -.297em 0 -.46em, -.82em -.09em 0 -.477em;
    }

    100% {
        box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
    }

    5%,
    95% {
        box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
    }

    10%,
    59% {
        box-shadow: 0 -.83em 0 -.4em, -.087em -.825em 0 -.42em, -.173em -.812em 0 -.44em, -.256em -.789em 0 -.46em, -.297em -.775em 0 -.477em;
    }

    20% {
        box-shadow: 0 -.83em 0 -.4em, -.338em -.758em 0 -.42em, -.555em -.617em 0 -.44em, -.671em -.488em 0 -.46em, -.749em -.34em 0 -.477em;
    }

    38% {
        box-shadow: 0 -.83em 0 -.4em, -.377em -.74em 0 -.42em, -.645em -.522em 0 -.44em, -.775em -.297em 0 -.46em, -.82em -.09em 0 -.477em;
    }

    100% {
        box-shadow: 0 -.83em 0 -.4em, 0 -.83em 0 -.42em, 0 -.83em 0 -.44em, 0 -.83em 0 -.46em, 0 -.83em 0 -.477em;
    }
}

@keyframes round {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
