.maps {
    &--wrapper {
        width: 100%;
        height: 500px;
        margin: 0 0 $default-margin;

        .map {
            width: 100%;
            height: 100%;
        }
    }
}
