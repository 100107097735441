h1 {
    color: $text-color;
    font-size: 42px;
    line-height: 58px;
    font-weight: 300;

    @include breakpoint(sm) {
        font-size: 26px;
        line-height: 42px;
    }
}

h2 {
    color: $text-color;
    font-size: 42px;
    line-height: 58px;
    font-weight: 300;

    @include breakpoint(sm) {
        font-size: 26px;
        line-height: 42px;
    }
}

h3 {
    color: $text-color;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}

h4 {
    color: $text-color;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}

h5 {
    color: $text-color;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

h6 {
    color: $text-color;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.subline {
    &__ {
        font-weight: 700;
    }
}
