.social-follow {
    display: block;
    font-size: 0;
    line-height: 0;
    margin: 0 -5px;

    &--item {
        display: inline-block;
        margin: 0 5px 15px;

        a {
            @include border-radius(50%);
            display: block;
            width: 40px;
            height: 41px;
            text-indent: -99999px;
            overflow: hidden;
            background: $customer-color url("/assets/img/social-media.svg") no-repeat 0 0;
        }

        &__facebook {
            a {
                background-position: 2px 2px;
                background-color: $facebook-color;
            }
        }

        &__twitter {
            a {
                background-position: 2px -34px;
                background-color: $twitter-color;
            }
        }

        &__youtube {
            a {
                background-position: 2px -70px;
                background-color: $youtube-color;
            }
        }

        &__linkedin {
            a {
                background-position: 2px -106px;
                background-color: $linkedin-color;
            }
        }

        &__instagram {
            a {
                background-position: 2px -142px;
                background-color: $instagram-color;
            }
        }

        &__googleplus {
            a {
                background-position: 2px -178px;
                background-color: $googleplus-color;
            }
        }

        &__pinterest {
            a {
                background-position: 2px -214px;
                background-color: $pinterest-color;
            }
        }

        &__newsletter {
            a {
                background-position: 2px -286px;
                background-color: $newsletter-color;
            }
        }
    }
}

.social-share {
    float: right;

    @include breakpoint(sm) {
        float: none;
        clear: both;
        margin: 0 0 15px;
    }

    &--toggle {
        position: relative;
        display: inline-block;
        color: $white;
        font-size: 14px;
        line-height: 30px;
        font-weight: 600;
        padding: 0 0 0 40px;
        background: none;

        &::before {
            @include border-radius(50%);
            position: absolute;
            left: 0;
            display: block;
            width: 30px;
            height: 30px;
            content: "";
            background: $white url("/assets/img/social-share.svg") no-repeat 0 -300px;

            @include breakpoint(sm) {
                background-position: 0 -270px;
            }
        }
    }

    &--wrapper {
        @include transition(max-height .5s);
        position: absolute;
        right: 0;
        bottom: 0;
        width: 300px;
        max-height: 0;
        color: $white;
        font-size: 14px;
        line-height: 30px;
        font-weight: 600;
        text-align: left;
        background: $blue-hover;
        overflow: hidden;

        @include breakpoint(sm) {
            width: 100%;
        }
    }

    &.active {
        .social-share {
            &--wrapper {
                max-height: 480px;
            }
        }
    }

    &--label {
        position: relative;
        display: block;
        padding: 10px 10px 10px 50px;
        margin: 0 0 10px;
        background: $blue;

        @include breakpoint(sm) {
            display: block;
        }

        &::before {
            @include border-radius(50%);
            position: absolute;
            top: 10px;
            left: 10px;
            display: block;
            width: 30px;
            height: 30px;
            content: "";
            background: $white url("/assets/img/social-share.svg") no-repeat 0 -270px;
        }
    }

    &--url {
        margin: 0 20px 20px;

        input {
            @include border-radius(20px);
            width: 100%;
            height: 40px;
            color: $blue-light;
            font-size: 12px;
            line-height: 22px;
            padding: 9px 20px;
            background: $white;
            border: 0;
        }
    }

    &--close {
        position: absolute;
        top: 5px;
        right: 5px;
        display: block;
        width: 40px;
        height: 40px;
        overflow: hidden;
        text-indent: -99999px;
        background: url("/assets/img/btn-icons.svg") no-repeat 0 -240px;
    }

    &--list {
        display: block;
        margin: 0 0 10px;
    }

    &--item {
        display: block;
        margin: 0;

        a {
            position: relative;
            display: block;
            color: $white;
            padding: 10px 20px 10px 70px;

            &::before {
                @include border-radius(50%);
                position: absolute;
                top: 10px;
                left: 20px;
                display: block;
                width: 30px;
                height: 30px;
                content: "";
                background: $white url("/assets/img/social-share.svg") no-repeat 0 0;
            }
        }

        &__facebook {
            a {
                &::before {
                    background-position: 0 0;
                }
            }
        }

        &__twitter {
            a {
                &::before {
                    background-position: 0 -30px;
                }
            }
        }

        &__youtube {
            a {
                &::before {
                    background-position: 0 -60px;
                }
            }
        }

        &__linkedin {
            a {
                &::before {
                    background-position: 0 -90px;
                }
            }
        }

        &__instagram {
            a {
                &::before {
                    background-position: 0 -120px;
                }
            }
        }

        &__googleplus {
            a {
                &::before {
                    background-position: 0 -150px;
                }
            }
        }

        &__pinterest {
            a {
                &::before {
                    background-position: 0 -180px;
                }
            }
        }

        &__whatsapp {
            a {
                &::before {
                    background-position: 0 -210px;
                }
            }
        }

        &__email {
            a {
                &::before {
                    background-position: 0 -240px;
                }
            }
        }
    }
}

.social-mail-popup {
    display: none;
}
