// All variables used in project; fonts, colors, basic and images

$default-margin: 15px;
$text-font: "Open Sans", sans-serif; // 300, 700
$navigation-font: "Titillium Web", sans-serif;  // 300, 700

$text-size: 16px;
$text-color: #4f4f4f;

// BEM like color naming http://www.juliecameron.com/blog/2013/11/06/bem-naming-for-sass-color-variables-what1/

// -- colors:start -- //
$customer-color: #00a2e6 !default;
$white: #fff;
$white-grey: #f6f6f6;
$white-trans: rgb(255 255 255 / .5);
$black: #2d2d2d;
$black-trans: rgb(0 0 0 / .9);
$black-transinfo: rgb(0 0 0 / .8);
$grey: #dbdbdb;
$grey-light-2: #b4b4b4;
$grey-light-3: #eaeaea;
$grey-dark: #a2a2a2;
$grey-extra-dark: #4f4f4f;
$grey-dark-light: #aaa;
$grey-dark-border: #d8d8d8;
$grey-extra-dark-border: #a8a8a8;
$grey-light: #c3c3c3;
$grey-light-hover: darken($grey-light, 10%);
$blue-dark: #0079b9 !default;
$blue-light: #50ccf4 !default;
$blue-gradient-1: #50ccf4 !default;
$blue-gradient-2: #0079b9 !default;

// New colors
$black: #000;
$black-trans: transparentize($black, .5);
$grey: #f6f6f6;
$grey-light: #f1f1f1;
$grey-dark: #e5e5e5;
$grey-darker: #b2b2b2;
$grey-zipcode: #dcdcdc;
$blue: #00a2e6 !default;
$blue-hover: darken($blue, 10%) !default;
$blue-trans: transparentize($blue, .9) !default;
$red: #ed3023;
$red-hover: darken($red, 10%);
$red-trans: transparentize($red, .9);
$red-dark: darken($red, 10%);
$red-gradient-1: #ed3023 !default;
$red-gradient-2: #f16320 !default;
$orange: #f9a917;
$orange-hover: darken($orange, 10%);
$orange-trans: transparentize($orange, .1);
$green: #a3cd39 !default;
$green-hover: darken($green, 10%) !default;
$green-trans: transparentize($green, .1) !default;
$purple: #ad298c !default;
$purple-hover: darken($purple, 10%) !default;
$purple-dark: darken($purple, 10%) !default;
$purple-trans: transparentize($purple, .1) !default;
$dark-purple: #4a256c;
$dark-purple-hover: darken($dark-purple, 10%);
$email-color: #0a88ff;

// $email-color__hover: #006ed6;
$facebook-color: #0079b9;

// $facebook-color__hover: #006ed6;
$twitter-color: #00a2e6;

// $twitter-color__hover: #0eaad6;
$youtube-color: #f00;

// $youtube-color__hover: #b21627;
$linkedin-color: #0077b5;

// $linkedin-color__hover: #005983;
$instagram-color: #833ab4;

// $instagram-color__hover: #0c3a5b;
$googleplus-color: #dd4b39;

// $googleplus-color__hover: #5d0e18;
$pinterest-color: #bd081c;

// $pinterest-color__hover: #df1c31;
$whatsapp-color: #25d366;
$newsletter-color: #69cff9;
