.navigation__slide-down {
    &.on {
        .nav-toggle {
            &--icon {
                .one {
                    @include transform(rotate(45deg) translate(7px, 7px));
                }

                .two {
                    opacity: 0;
                }

                .three {
                    @include transform(rotate(-45deg) translate(7px, -7px));
                }
            }
        }

        .main-navigation__header {
            @include media-breakpoint-down(lg) {
                @include transition(max-height 1.2s);
                max-height: 999px;
            }
        }
    }

    .nav-toggle {
        &--icon {
            float: left;
            display: none;
            width: 50px;
            height: 50px;
            padding: 7px;
            margin: 0;

            @include media-breakpoint-down(lg) {
                display: inline-block;
            }

            .one,
            .two,
            .three {
                @include border-radius(5px);
                @include transition(opacity .3s, transform .3s);
                width: 30px;
                height: 4px;
                background: $text-color;
                margin: 6px auto;
                backface-visibility: hidden;
            }
        }
    }

    .main-navigation__header {
        @include media-breakpoint-down(lg) {
            @include transition(max-height .8s ease-out);
            max-height: 0;
            overflow: hidden;
        }
    }
}
