.richtext {
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;

    h1 {
        font-weight: bold;
    }

    h3 {
        font-size: 36px;
        line-height: 42px;
        font-weight: bold;
    }

    h4 {
        font-size: 36px;
        line-height: 42px;
        font-weight: 300;
    }

    h5 {
        font-weight: bold;
    }

    ul,
    ol {
        display: block;
        list-style-type: disc;
        padding: 0 0 0 20px;
    }

    .sisea-results-list ul {
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 15px;

            h2 {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    ol {
        list-style-type: decimal;
    }

    p {
        font-weight: 400;
    }

    a:not(.btn) {
        color: $customer-color;
        outline: none;

        &:hover,
        &:focus {
            outline: none;
        }
    }

    strong,
    b {
        font-weight: 600;
    }

    em,
    i {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    address {
        font-weight: 400;
    }

    iframe {
        width: 100%;
        height: 420px;

        @include media-breakpoint-down(lg) {
            height: 380px;
        }

        @include media-breakpoint-down(md) {
            height: 330px;
        }
    }

    // Blockquote
    blockquote {
        padding: 0 0 0 24px;
        margin: 25px 0 40px;
        border-left: 5px solid $blue;

        p {
            font-size: 20px;
            line-height: 28px;
            font-style: italic;

            &::before {
                content: '"';
            }

            &::after {
                content: '"';
            }
        }
    }

    pre {
        font-family: monospace;
    }

    table {
        width: 100%;

        tr {
            &:last-of-type {
                th,
                td {
                    border-bottom: 0;
                }
            }

            th,
            td {
                padding: 5px 0;
                border-bottom: 1px solid $grey;
            }
        }
    }

    hr {
        height: 1px;
        background: $text-color;
        border: 0;
    }

    img {
        @extend .img-fluid;
    }

    &__business {
        padding: 0 5px 30px;

        @include breakpoint(md) {
            padding: 0;
        }

        @include breakpoint(sm) {
            padding: 0;
        }

        h3 {
            text-transform: uppercase;
            font-size: 36px;
            line-height: 48px;
            color: $black;
            font-weight: 300;
        }

        ol {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding-left: 25px;

                &::before {
                    content: "";
                    width: 17px;
                    height: 17px;
                    background: url("/assets/img/check-icon.svg") no-repeat center transparent;
                    position: absolute;
                    left: 0;
                    top: 4px;
                    background-size: contain;
                }
            }
        }
    }
}
