[data-zipcode-checker] {
    [data-zipcode-checker-room] {
        display: none;

        &.active {
            display: block;
        }
    }
}

[data-show-current] {
    display: none;
}

[data-checkout-cart] {
    position: sticky;
    top: 20px;
}

[data-flow="update"] {
    [data-toggle-partner] {
        display: none;
    }
}

[data-field-partner] {
    label[for="partner"] {
        display: none;
    }
}

[data-toggle-partner] {
    label {
        color: $orange;
        padding: unset;
        font-weight: 600;
        display: block;
        margin-bottom: -7px;

        &:hover {
            color: $orange-hover;
            text-decoration: underline;
        }

        i {
            display: none !important; // scss-lint:disable ImportantRule
        }
    }
}

// Checkout Block

.checkout-block {
    margin: 0 0 40px;
    padding: 30px 15px 16px;
    border: 1px solid $grey-darker;

    h2 {
        font-size: 26px;
        line-height: 36px;
        font-weight: 600;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

.checkout-wrapper {
    .birthday .form-control {
        @include media-breakpoint-down(md) {
            margin-bottom: 15px;
        }

        &.last {
            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }
        }
    }

    .partnercode-desc {
        font-size: 12px;
        width: 45vw;

        @include media-breakpoint-down(sm) {
            width: 90vw;
        }
    }

    .mode-compatible {
        ul {
            padding: 0 15px 127px;

            ul {
                font-size: 16px;
                padding: 15px;
                list-style: disc;
            }
        }
    }
}

.checkout-notification {
    background: $grey-light;
    padding: 10px 15px;
    margin-bottom: 40px;

    p {
        margin: 0 0 10px;
    }

    ul {
        padding: 0 15px;
        list-style-type: unset;
        margin-left: 10px;
    }
}

// Checkout Navigation

.checkout-navigation {
    &--wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 $default-margin;
        margin: 0 0 40px;
        border-bottom: 1px solid $grey-zipcode;
        list-style: none;

        @include media-breakpoint-down(md) {
            margin: 0 0 30px;
            border-bottom: 0;
        }

        li {
            position: relative;
            flex: 1;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                padding: 0 0 10px;
                margin: 0 0 10px;
                text-align: center;
                border-bottom: 1px solid $grey-zipcode;
            }

            &::after {
                @include opacity(.5);
                position: absolute;
                left: 0;
                bottom: -16px;
                display: none;
                width: 100%;
                height: 2px;
                content: "";
                background: $grey-extra-dark;

                @include media-breakpoint-down(md) {
                    bottom: -1px;
                }
            }

            &.valid {
                &::after {
                    @include opacity(1);
                    display: block;
                }
            }

            a,
            span {
                @include opacity(.5);
                display: inline-block;
                position: relative;
                color: $grey-extra-dark;
                font-size: 26px;
                line-height: 32px;
                font-weight: 300;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    line-height: 26px;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }

                &::after {
                    position: absolute;
                    left: 0;
                    bottom: -18px;
                    display: none;
                    width: 100%;
                    height: 2px;
                    content: "";
                    background: $grey-extra-dark;

                    @include media-breakpoint-down(md) {
                        bottom: -13px;
                    }
                }
            }

            a {
                display: block;
            }

            &.active {
                a,
                span {
                    &::after {
                        display: block;
                    }
                }
            }

            &.valid,
            &.active {
                a,
                span {
                    @include opacity(1);
                }
            }
        }
    }
}

// Checkout Pagination

.checkout-pagination {
    &--wrapper {
        text-align: right;
        margin: 0 0 40px;

        .btn {
            display: inline;

            &--prev {
                float: left;
                margin: 0 20px 0 0;

                @include media-breakpoint-down(md) {
                    margin: 0 5px 0 0;
                }
            }

            &--next {
                margin: 0 0 0 20px;

                @include media-breakpoint-down(md) {
                    margin: 0 0 0 5px;
                }
            }
        }

        span {
            display: inline;
            line-height: 44px;
        }
    }
}

// Checkout Message

.checkout-message {
    @include border-radius(25px);
    position: relative;
    color: $white;
    cursor: pointer;
    padding: 12px 45px 0 15px;
    margin: 0 0 $default-margin;
    overflow: hidden;

    p {
        margin: 0 0 10px;
    }

    &--success {
        background-color: $green;
    }

    &--error {
        background-color: $red;
    }

    &::after {
        content: "\00D7";
        position: absolute;
        right: 5px;
        top: 0;
        display: block;
        font-size: 30px;
        padding: 11px;
        cursor: pointer;
    }
}

// Checkout Zipcode

.checkout-zipcode {
    &--block {
        text-align: center;

        h2 {
            margin: 0;
        }

        p {
            margin: 0 0 30px;
        }

        &__green {
            padding: 26px 14px 25px;
            border: 2px solid $green;

            h2 {
                color: $green;
            }
        }

        &__orange {
            padding: 26px 14px 25px;
            border: 2px solid $orange;

            h2 {
                color: $orange;
            }
        }

        &__red {
            padding: 26px 14px 25px;
            border: 2px solid $red;

            h2 {
                color: $red;
            }
        }

        &.active {
            form {
                font-size: 0;

                .btn {
                    display: inline;
                    margin: 0 10px;

                    @include media-breakpoint-down(xs) {
                        display: block;
                        width: 100%;
                        margin: 0 0 10px;
                    }
                }
            }
        }
    }
}

// Checkout Category

.checkout-category {
    &--block {
        margin: 0 0 20px;

        &__title {
            border-bottom: 1px solid $grey-zipcode;
            padding: 0 0 15px;

            h2 {
                font-size: 26px;
                line-height: 36px;
                font-weight: 600;
                margin: 0;
            }
        }

        &__content {
            padding: 20px 0 0;
        }
    }
}

// Checkout Products

.checkout-products {
    &--wrapper {
        @include make-row;
        margin: 0 -10px 5px;

        @include media-breakpoint-down(sm) {
            padding-top: 10px;
        }
    }

    &--item {
        @include make-col(4);
        padding: 0 10px;

        @include media-breakpoint-down(lg) {
            @include make-col(6);
        }

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        &__horizontal {
            @include make-col(12);
        }
    }

    &--note {
        margin: -10px 0 0;
        color: $grey-darker;
        font-size: 14px;
        text-align: center;
        font-style: italic;
    }
}

// Checkout Product

.checkout-product {
    &--block {
        @include make-row;
        position: relative;
        height: calc(100% - 15px);
        padding: 15px 15px 137px;
        margin: 0 0 $default-margin;
        border: 1px solid $grey-darker;

        &.active {
            border-color: $grey-extra-dark;
            box-shadow: inset 0 0 0 1px $grey-extra-dark;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;
        }

        .checkout-message {
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
        }

        .checkout-addons {
            &--wrapper {
                margin-top: 20px;

                .checkout-addon {
                    &--block {
                        position: inherit;
                    }
                }
            }
        }

        &__horizontal {
            height: auto;
            padding-left: 7px;
            padding-right: 8px;
            padding-bottom: 0;

            .checkout-product {
                &--image {
                    flex: 0 0 100px;
                    max-width: 100px;
                    padding: 0 8px;

                    @include media-breakpoint-down(sm) {
                        display: block;
                        max-width: 100%;
                        width: 100%;
                        flex: 0 0 auto;
                        text-align: center;
                    }

                    img {
                        width: 75px;
                        height: 75px;
                    }

                    ~ .checkout-product {
                        &--actions {
                            padding-left: 107px;

                            @include media-breakpoint-down(sm) {
                                padding-left: 8px;
                            }
                        }
                    }
                }

                &--title {
                    flex: 0 0 240px;
                    max-width: 240px;
                    min-width: 0;
                    padding: 0 8px;
                }

                &--description {
                    margin: 0;
                    min-width: 0;
                    padding: 0 8px;

                    @include media-breakpoint-down(sm) {
                        flex: 0 0 auto;
                        width: 100%;
                        max-width: 100%;
                    }

                    h2 {
                        padding: 0;

                        strong {
                            display: inline-block;
                        }
                    }

                    ul {
                        display: flex;
                        flex-wrap: wrap;

                        li {
                            flex: 0 0 50%;

                            @include media-breakpoint-down(md) {
                                flex: 0 0 100%;
                            }
                        }
                    }
                }

                &--description-small {
                    margin: 0;
                    min-width: 0;
                    padding: 0 8px;

                    @include media-breakpoint-down(sm) {
                        flex: 0 0 auto;
                        width: 100%;
                        max-width: 100%;
                    }

                    h2 {
                        padding: 0;

                        strong {
                            display: inline-block;
                        }
                    }
                }

                &--price {
                    position: static;
                    padding: 0 8px;
                    flex: 0 0 120px;
                    max-width: 120px;

                    @include media-breakpoint-down(lg) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }

                &--actions {
                    position: static;
                    flex: 0 0 100%;
                    padding: 0 8px;
                    text-align: left;

                    @include media-breakpoint-down(lg) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }

                &--highlight {
                    @include media-breakpoint-up(xl) {
                        transform: rotate(-45deg);
                        left: -47px;
                        right: auto;
                    }
                }
            }

            .checkout-addons {
                &--wrapper {
                    flex: 1;
                    padding: 0 0 0 115px;

                    @include media-breakpoint-down(sm) {
                        padding: 0 8px;
                    }
                }
            }

            &[data-checkout-product="phone"],
            &[data-checkout-product="phone2nd"] {
                .checkout-product {
                    &--description {
                        ul {
                            li {
                                flex: 0 0 100%;
                            }
                        }
                    }
                }
            }
        }

        &__blue {
            h2 {
                color: $blue;
            }

            &.active {
                border-color: $blue;
                box-shadow: inset 0 0 0 1px $blue;
            }

            .checkout-product {
                &--highlight {
                    &::before,
                    &::after {
                        border-top-color: $blue-hover;
                    }

                    span {
                        &::before {
                            border-right-color: $blue;
                        }

                        &::after {
                            border-left-color: $blue;
                        }

                        strong {
                            background-color: $blue;
                        }
                    }
                }
            }
        }

        &__red {
            h2 {
                color: $red;
            }

            &.active {
                border-color: $red;
                box-shadow: inset 0 0 0 1px $red;
            }

            .checkout-product {
                &--highlight {
                    &::before,
                    &::after {
                        border-top-color: $red-hover;
                    }

                    span {
                        &::before {
                            border-right-color: $red;
                        }

                        &::after {
                            border-left-color: $red;
                        }

                        strong {
                            background-color: $red;
                        }
                    }
                }
            }
        }

        &__green {
            h2 {
                color: $green;
            }

            &.active {
                border-color: $green;
                box-shadow: inset 0 0 0 1px $green;
            }

            .checkout-product {
                &--highlight {
                    &::before,
                    &::after {
                        border-top-color: $green-hover;
                    }

                    span {
                        &::before {
                            border-right-color: $green;
                        }

                        &::after {
                            border-left-color: $green;
                        }

                        strong {
                            background-color: $green;
                        }
                    }
                }
            }
        }

        &__purple {
            h2 {
                color: $purple;
            }

            &.active {
                border-color: $purple;
                box-shadow: inset 0 0 0 1px $purple;
            }

            .checkout-product {
                &--highlight {
                    &::before,
                    &::after {
                        border-top-color: $purple-hover;
                    }

                    span {
                        &::before {
                            border-right-color: $purple;
                        }

                        &::after {
                            border-left-color: $purple;
                        }

                        strong {
                            background-color: $purple;
                        }
                    }
                }
            }
        }
    }

    &--image {
        flex: 1;
        align-self: center;
        min-width: 40px;
        max-width: 40px;
        margin: 0;

        img {
            width: 40px;
            height: 40px;
            margin: 0 0 15px;
        }
    }

    &--title {
        flex: 1;
        min-width: 100%;

        h2 {
            font-size: 26px;
            line-height: 28px;
            font-weight: 700;

            strong {
                display: block;
                font-weight: 700;
            }
        }

        ul {
            padding: 0;
            list-style-type: disc;
            list-style-position: inside;
        }
    }

    &--description,
    &--description-small {
        flex: 1;
        min-width: 100%;
        height: 100%;
        margin: -62px 0 0;

        h2 {
            font-size: 26px;
            line-height: 28px;
            font-weight: 700;
            padding: 0 0 0 50px;

            strong {
                display: block;
                font-weight: 700;
            }
        }

        ul {
            padding: 0;
            list-style-type: disc;
            list-style-position: inside;
        }
    }

    &--description-small {
        font-size: 14px;
        line-height: 22px;
    }

    &--price {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 61px;

        &.showcase {
            bottom: 10px;
        }

        .price {
            font-size: 16px;
            line-height: 24px;

            strong {
                display: block;
                font-size: 26px;
                line-height: 36px;
                font-weight: 700;
            }
        }
    }

    &--actions {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 0;
        text-align: center;
        margin: 0;

        .btn {
            display: inline-block;
            width: 190px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    &--highlight {
        transform: rotate(45deg);
        position: absolute;
        top: 23px;
        right: -47px;
        display: block;
        height: 45px;
        overflow: hidden;

        &::before,
        &::after {
            position: absolute;
            bottom: -10px;
            display: block;
            width: 10px;
            height: 10px;
            content: "";
            border-top: 15px solid $black;
            border-bottom: 15px solid transparent;
        }

        &::before {
            left: 5px;
            top: 30px;
            border-right: 15px solid transparent;
        }

        &::after {
            right: 6px;
            bottom: -15px;
            border-left: 15px solid transparent;
        }

        span {
            position: relative;
            display: block;
            padding: 0 36px;
            overflow: hidden;

            &::before,
            &::after {
                position: absolute;
                top: -20px;
                display: block;
                width: 0;
                height: 0;
                content: "";
                border-top: 50px solid transparent;
                border-bottom: 50px solid transparent;
            }

            &::before {
                left: 5px;
                border-right: 50px solid $grey-extra-dark;
            }

            &::after {
                right: 5px;
                border-left: 50px solid $grey-extra-dark;
            }

            strong {
                position: relative;
                display: inline-block;
                height: 30px;
                padding: 3px 0;
                color: $white;
                font-size: 14px;
                line-height: 24px;
                text-transform: uppercase;
                background: $grey-extra-dark;
                z-index: 1;
            }
        }
    }

    &--item:last-of-type &--actions {
        @include media-breakpoint-only(md) {
            text-align: left;
        }
    }
}

// Checkout Addons

.checkout-addons {
    &--wrapper {
        @include make-row;
        margin: 0 0 5px;
        display: none;

        &.available {
            display: flex;
        }

        &.tvchannelpackage,
        &.phoneunlimited {
            position: relative;
            border: 1px solid $grey-darker;
            margin: 0 0 25px;

            .checkout-addon {
                &--block {
                    position: inherit;

                    &__medium {
                        padding: 0 15px;
                    }
                }
            }
        }
    }

    &--title {
        display: block;
        width: 100%;
        padding: 10px;

        h2 {
            font-size: 26px;
            line-height: 36px;
            font-weight: 700;
            margin: 0;
        }
    }

    &--item {
        @include make-col(12, 12);

        &__small {
            @include make-col(4, 12);

            @include media-breakpoint-down(xs) {
                @include make-col(12, 12);
            }
        }
    }
}

// Checkout Addon

.checkout-addon {
    &--block {
        @include make-row;
        position: relative;
        display: none;
        padding: 15px 7px 1px;
        margin: 0 0 $default-margin;
        border: 1px solid $grey-darker;

        &.available {
            display: flex;
        }

        &.active {
            border-color: $grey-extra-dark;
            box-shadow: inset 0 0 0 1px $grey-extra-dark;
        }

        > form {
            @include make-row;
            padding: 0;
            margin: 0;
            width: 100%;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;
        }

        .checkout-addons {
            &--wrapper {
                margin-top: 20px;
            }
        }

        .checkout-message {
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            z-index: 10;
        }

        .form {
            &-columns {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;

                .form {
                    &-group {
                        flex: 1;
                        padding: 0 15px;

                        @include media-breakpoint-down(md) {
                            flex: 0 0 100%;
                        }
                    }
                }
            }

            &-group-floating {
                position: absolute;
                right: 100%;

                @include media-breakpoint-down(lg) {
                    position: static;
                }

                label {
                    display: none;
                }
            }

            &-control {
                min-width: 100px;
            }
        }

        &__small {
            margin: 0;
            padding: 0;
            border: 0;

            // scss-lint:disable ImportantRule
            &.active {
                box-shadow: none !important;
            }

            // scss-lint:enable ImportantRule

            .checkout-addon {
                &--description {
                    @include make-col(12);
                }
            }
        }

        &__medium {
            margin: 0 -8px;
            padding: 0;
            border: 0;

            // scss-lint:disable ImportantRule
            &.active {
                box-shadow: none !important;
            }

            // scss-lint:enable ImportantRule

            .checkout-addon {
                &--block {
                    margin: 0;
                }

                &--description {
                    h2 {
                        color: $black;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        &__large {
            .price {
                font-size: 16px;
                line-height: 24px;

                strong {
                    display: block;
                    font-size: 26px;
                    line-height: 36px;
                    font-weight: 700;
                }
            }

            .checkout-addon {
                &--block {
                    margin: 0;
                }
            }
        }

        &__category {
            padding: 15px 15px 1px;

            h2 {
                font-size: 26px;
                line-height: 28px;
                font-weight: 700;
            }

            .checkout-addons {
                &--item {
                    @include make-col(4);

                    @include media-breakpoint-down(md) {
                        @include make-col(6);
                    }

                    @include media-breakpoint-down(sm) {
                        @include make-col(12);
                    }
                }
            }
        }

        &__blue {
            &.active {
                border-color: $blue;
                box-shadow: inset 0 0 0 1px $blue;
            }
        }

        &__red {
            &.active {
                border-color: $red;
                box-shadow: inset 0 0 0 1px $red;
            }
        }

        &__green {
            &.active {
                border-color: $green;
                box-shadow: inset 0 0 0 1px $green;
            }
        }

        &__purple {
            &.active {
                border-color: $purple;
                box-shadow: inset 0 0 0 1px $purple;
            }
        }

        &__orange {
            &.active {
                border-color: $orange;
                box-shadow: inset 0 0 0 1px $orange;
            }
        }
    }

    &--image {
        @include make-col(3);
        padding: 0 8px;
        margin: 0 0 $default-margin;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &--description {
        flex: 1;
        max-width: none;
        padding: 0 8px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }

        h2 {
            font-size: 26px;
            line-height: 36px;
            font-weight: 700;

            + * {
                margin-top: -$default-margin;
            }
        }

        ul {
            padding: 0 0 0 20px;
            list-style-type: disc;
        }
    }

    &--actions {
        position: relative;
        flex: 0 0 206px;
        max-width: 206px;
        padding: 0 8px;
        text-align: right;

        @include media-breakpoint-down(lg) {
            order: 3;
        }

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .btn {
            display: inline-block;
            width: 190px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    &--content {
        @include make-col(12);
        padding: 0 10px;
    }
}

// Checkout cart

.checkout-cart {
    &--block {
        overflow: hidden;
        padding: 15px 15px 0;
        margin: 0 0 $default-margin;
        background: $grey-light;

        h2 {
            color: $orange;
            font-size: 26px;
            line-height: 36px;
            font-weight: 600;
        }

        .btn {
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    &--table {
        width: auto;
        margin: 0 -15px $default-margin;
        font-size: 14px;

        table {
            tr {
                td {
                    vertical-align: top;
                    padding: 5px;

                    &:first-of-type {
                        padding-left: 15px;
                    }

                    &:last-of-type {
                        padding-right: 15px;
                    }

                    &.label {
                        width: 65%;
                    }

                    &.price {
                        width: 35%;
                        text-align: right;
                    }
                }
            }

            tfoot {
                font-weight: 600;
                background-color: $grey-dark;
            }
        }
    }

    &--button {
        padding: 5px 0 20px;
    }

    &--note .note-text {
        color: $grey-darker;
        font-style: italic;
        font-size: 14px;
    }

    &--help {
        font-size: 15px;
        font-style: italic;
        padding: 15px;
        margin: 0 -15px;
        text-align: center;
        background: $white;
        overflow: hidden;
    }
}

.checkout-cart-summary {
    &--table {
        table {
            tr {
                td {
                    vertical-align: top;
                    padding: 5px 0;

                    &.label {
                        width: 65%;
                    }

                    &.price {
                        width: 35%;
                        text-align: right;
                    }
                }
            }

            tfoot {
                font-weight: 600;
                border-top: 1px solid $grey-dark;
            }
        }
    }
}

// Checkout Contact

.checkout-contact {
    border-top: 1px solid $grey-dark;
    border-bottom: 1px solid $grey-dark;
    margin: 0 0 40px;

    &--block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 25px;
        padding: 40px 0 0;
        align-items: center;
    }

    &--image {
        @include make-col(3, 12);

        @include breakpoint(sm) {
            @include make-col(12, 12);
        }

        img {
            @include border-radius(50%);
            margin: 0 0 $default-margin;
        }
    }

    &--content {
        @include make-col(9, 12);
        padding: 0 0 0 40px;

        @include breakpoint(sm) {
            @include make-col(12, 12);
            padding: 0;
        }
    }
}

// Toggle Block

.toggle-block {
    &--btn {
        color: $blue;
        font-style: italic;
        text-transform: lowercase;
        padding: 0;
        margin: 0 0 15px;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &::after {
            display: inline-block;
            width: 14px;
            height: 7px;
            content: "";
            margin: 1px 8px;
            background: url("/assets/img/block-toggle-icon.svg") no-repeat 0 0;
        }
    }

    &.active {
        .toggle-block {
            &--btn {
                &::after {
                    @include transform(rotate(180deg));
                }
            }
        }
    }

    &--content {
        display: none;
    }
}

// Info data

.info-data {
    @include border-radius(50%);
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: $grey-extra-dark url("/assets/img/data-info-icon.svg") no-repeat center center;

    &.active {
        span {
            display: block;
        }
    }

    span {
        @include border-radius(5px);
        position: absolute;
        top: 34px;
        left: -150px;
        display: none;
        width: 300px;
        padding: 15px 15px 0;
        margin: 0 8px;
        color: $white;
        font-size: 14px;
        line-height: 22px;
        background: $grey-extra-dark;
        z-index: 99;

        &::before {
            @include transform(rotate(45deg));
            position: absolute;
            top: 0;
            left: 50%;
            display: block;
            width: 16px;
            height: 16px;
            content: "";
            margin: -8px 0 0 -8px;
            background: $grey-extra-dark;
        }
    }

    &:hover span {
        display: block;
    }

    &.info-relocate {
        margin: -2px 3px;
    }
}

.mode-compatible {
    ul {
        padding: 0 15px 77px;
        list-style: none;

        ul {
            font-size: 16px;
            padding: 15px;
            list-style: disc;
        }
    }

    .checkout-product {
        margin: unset;

        &--block {
            padding: unset;
        }

        &--description {
            margin: 0;

            h2 {
                background: $grey-extra-dark;
                color: $white;
                padding: 10px;
                text-align: center;
                margin: 0;
            }
        }
    }

    .info-data {
        display: block;
        border-radius: unset;
        background: unset;
        width: unset;
        height: unset;
        cursor: unset;

        span {
            border-radius: unset;
            display: block;
            position: relative;
            top: 0;
            left: 0;
            width: unset;
            padding: 15px 0 0;
            margin: 0;
            color: unset;
            background: unset;

            &::before {
                display: none;
            }
        }
    }
}

.checkout-compatible {
    &--icon {
        &--tv {
            width: 40px;
            height: 40px;
            position: relative;
            top: 15px;
            margin-right: 10px;
            display: inline-block;
            background: url("/assets/img/services/tv.svg") no-repeat 0 0;

            span {
                line-height: 40px;
                padding-left: 50px;
                color: $red;
                font-size: 26px;
                font-weight: bold;
            }
        }

        &--internet {
            width: 40px;
            height: 40px;
            position: relative;
            top: 15px;
            margin-right: 10px;
            display: inline-block;
            background: url("/assets/img/services/internet.svg") no-repeat 0 0;

            span {
                line-height: 40px;
                padding-left: 50px;
                color: $blue;
                font-size: 26px;
                font-weight: bold;
            }
        }

        &--phone {
            width: 40px;
            height: 40px;
            position: relative;
            top: 15px;
            margin-right: 10px;
            display: inline-block;
            background: url("/assets/img/services/phone.svg") no-repeat 0 0;

            span {
                line-height: 40px;
                padding-left: 50px;
                color: $green;
                font-size: 26px;
                font-weight: bold;
            }
        }
    }
}

.phonecurrentnumber,
.phoneextranumber {
    .checkout-addon {
        &--content {
            .form-check {
                margin: 0;

                label {
                    display: block;
                }
            }

            .form-group {
                p {
                    margin: 0;
                }
            }
        }
    }
}
